import { ArrowDownIcon } from "@common/components/icons";
import { gtmPushData } from "@common/helpers/gtm";
import { getPrefixUrl } from "@modules/api/api";
import { ExtendedWarrantyRatesResponse } from "@modules/purchase/types/ExtendedWarrantyRates";
import { IAExtendedWarrantyPrograms } from "@modules/purchase/types/IAProgram";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { Event as HotjarEvent, hotjar } from "@modules/core/hotjar/types";
import useUser from "@modules/user/hooks/useUser";

type Props = {
  extWarrantyRates: ExtendedWarrantyRatesResponse;
};

const getAftermarketBrochureLink = (
  locale: string,
  programId: string,
  coverageTypes: string[]
) => {
  const coverageTypesQuery = coverageTypes.join(",");
  const rawUrl = `${getPrefixUrl()}/api/purchases/aftermarket/brochure/${programId}?lang=${locale}&coverageTypes=${coverageTypesQuery}`;

  return rawUrl.replace(/%2C/g, ",");
};

const WarrantyBrochure = ({ extWarrantyRates }: Props) => {
  const { t } = useTranslation(["common", "purchase"]);

  const { user } = useUser();

  const router = useRouter();
  const locale = router.locale || "en";

  const iaProgram = IAExtendedWarrantyPrograms.find(
    (p) => p.id === extWarrantyRates?.productDetails?.programId
  );

  const distinctUsedCoverageTypeIds = extWarrantyRates?.coverages.reduce<
    string[]
  >((acc, current) => {
    if (!acc.some((coverage) => coverage === current.type)) {
      acc.push(current.type);
    }
    return acc;
  }, []);

  const handleWarrantyBrochureClick = (
    e: React.MouseEvent<HTMLAnchorElement>
  ) => {
    e.preventDefault();

    const brochureLink = getAftermarketBrochureLink(
      locale,
      iaProgram?.id ?? "",
      distinctUsedCoverageTypeIds ?? []
    );

    window.open(brochureLink, "_blank");

    gtmPushData({
      event: "AftermarketPurchase",
      element: "Warranty Brochure",
      description: "Warranty Brochure Clicked",
      user_id: user?.id,
    });

    hotjar.event(HotjarEvent.WarrantyBrochure);
  };

  return (
    <div className="flex justify-between items-center bg-gray-25 p-4 rounded-md mt-8">
      <div className="px-2">
        <p className="font-semibold body-1 text-[#0C2340]">
          {t("purchase:explore_coverage_details")}
        </p>
        <p className="caption-2 mt-2 textg-gray-800">
          {t("purchase:download_pdf_description")}
        </p>
      </div>

      <a
        href="#"
        onClick={handleWarrantyBrochureClick}
        className="text-primary-bold font-medium underline flex items-center gap-1 hover:text-primary-hover"
      >
        {t("purchase:download_pdf")}
        <ArrowDownIcon className="w-4 h-4 text-primary-bold" />
      </a>
    </div>
  );
};

export default WarrantyBrochure;
