import Modal, { Props as ModalProps } from "@common/components/Modal";
import { ICO_INQUIRY_EMAIL } from "@common/constants";
import { useTranslation } from "next-i18next";
import TextLink from "@common/components/TextLink";
import { Event as HotjarEvent, hotjar } from "@modules/core/hotjar/types";
import { gtmPushData } from "@common/helpers/gtm";

type Props = Pick<ModalProps, "isOpen" | "onClose">;

const DealCreatedForOfferModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation(["common", "purchase", "tradeInPage"]);

  const handleOnClose = () => {
    gtmPushData({
      event: "ICO In Progress Modal",
      description: "ICO In Progress Modal Close",
      element: "Close",
    });

    hotjar.event(HotjarEvent.IcoInProgressModalClose);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      title={t("common:instant_cash_offer")}
      alignment="center"
    >
      <div className="md:w-96 mx-auto">
        <h1 className="mb-4 text-primary-bold">
          {t("purchase:your_offer_in_progress")}
        </h1>
        <h3 className="pb-6 text-primary-deep">
          {t("purchase:offer_processed_by_buying_team")}
        </h3>
        <p className="body-2 mt-1 text-gray-600">
          {t("purchase:contact_us_offer_questions")}
        </p>
        <TextLink
          className="text-gray-600"
          href={`mailto:${ICO_INQUIRY_EMAIL}`}
        >
          <a>{ICO_INQUIRY_EMAIL}</a>
        </TextLink>
      </div>

      <Modal.Buttons
        isPrimarySubmit
        primaryButtonText={t("common:contact_us")}
        onPrimaryClick={() => {
          gtmPushData({
            event: "ICO In Progress Modal",
            description: "ICO In Progress Modal Contact Us",
            element: "Contact Us",
          });
          hotjar.event(HotjarEvent.IcoInProgressModalContactUsClicked);

          window.location.href = `mailto:${ICO_INQUIRY_EMAIL}`;
          onClose();
        }}
        onSecondaryClick={() => {
          gtmPushData({
            event: "ICO In Progress Modal",
            description: "ICO In Progress Modal Cancel",
            element: "Cancel",
          });
          hotjar.event(HotjarEvent.IcoInProgressModalCancelClicked);

          onClose();
        }}
        secondaryButtonText={t("common:cancel")}
      />
    </Modal>
  );
};

export default DealCreatedForOfferModal;
