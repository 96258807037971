const locationQueryKeys = {
  all: ["locations"] as const,
  list: (dealerGroupId: number) =>
    [...locationQueryKeys.all, "list", dealerGroupId] as const,
  details: () => [...locationQueryKeys.all, "detail"] as const,
  detailBySlug: (slug: string) =>
    [...locationQueryKeys.details(), { slug }] as const,
};

export default locationQueryKeys;
