import Button from "@common/components/Button";
import Form from "@common/components/form";
import { useCallback, useEffect, useState } from "react";
import { FieldError, SubmitHandler, useForm } from "react-hook-form";
import useCreateLeadMutation from "@modules/leads/hooks/useCreateLeadMutation";
import { Trans, useTranslation } from "next-i18next";
import TextLink from "@common/components/TextLink";
import useGetLocations from "@modules/locations/hooks/useGetLocations";
import { ArrowRightIcon } from "@components/icons";
import FormContentHeader from "@modules/cms/components/FormContentHeader";
import classNames from "classnames";
import cloudflareImagesLoader from "@common/helpers/imageLoaders/cloudflareImagesLoader";
import Feedback from "@common/components/form/Feedback";
import { cleanParagraphTags } from "@common/helpers/cleanHtml";
import LoadingSpinner from "@common/components/LoadingSpinner";
import useUser from "@modules/user/hooks/useUser";
import useWindowDimensions from "@common/hooks/useWindowDimensions";
import {
  convertDealerLocationToMapLocation,
  findClosestLocation,
} from "@common/helpers/distanceMatrixHelper";
import customerValidation from "@common/helpers/validations/customerValidation";
import { gtmPushData } from "@common/helpers/gtm";
import { useRouter } from "next/router";
import useSessionStorage from "@common/hooks/useSessionStorage";
import { ReferralCampaign } from "@common/types/ReferralCampaign";
import { LocationWithNameOverride } from "@common/components/form/LocationPicker";
import { BackgroundImageGroup } from "../types/BackgroundImageGroup";
import FormSelectorBackgroundImage from "./FormSelectorBackgroundImage";
import { sizeClass } from "../types/SizeClass";
import { DEFAULT_FORM_SELECTOR_BACKGROUND_WIDTH } from "../constants/BackgroundImages";
import FormSubmissionModal from "./FormSubmissionModal";
import GetBackgroundStyle from "../helpers/getBackgroundStyle";
import { CmsBackgroundType } from "../types/CmsBackground";

type Props = {
  title: string;
  description: string;
  campaignTag: string;
  dealerId: string | undefined;
  backgroundImageGroup?: BackgroundImageGroup;
  locationDropdown: {
    hideLocations?: boolean;
    vinsolutionsId?: number;
  };
  referredBy: {
    isEnabled?: boolean;
    referredByLabel?: string;
  };
  messageBox: {
    isEnabled?: boolean;
    messageLabel?: string;
  };
  consentCheckbox: {
    isVisible?: boolean;
    consentCheckboxLabel?: string;
  };
  background: CmsBackgroundType;
};

type GeneralFormInput = {
  firstname: string;
  lastname: string;
  email: string;
  phoneNumber: string;
  preferredLocation: LocationWithNameOverride;
  referredBy: string;
  message: string;
  consent: boolean;
};

const GeneralForm = ({
  title,
  description,
  campaignTag,
  dealerId,
  backgroundImageGroup,
  locationDropdown,
  referredBy,
  messageBox,
  consentCheckbox,
  background,
}: Props) => {
  const { t } = useTranslation(["common"]);
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<GeneralFormInput>({
    mode: "onSubmit",
  });
  const router = useRouter();
  const { locations } = useGetLocations();
  const { user } = useUser();

  const setClosestLocationToCustomer = useCallback(() => {
    if (user && user.longitude && user.latitude) {
      const mapLocation = findClosestLocation(
        user.latitude,
        user.longitude,
        locations.map((l) => convertDealerLocationToMapLocation(l))
      );

      if (mapLocation) {
        const location = locations.find((l) => l.id === mapLocation.id);

        if (location) {
          setValue("preferredLocation", {
            ...location,
            nameOverride: `${user.locationCity}, ${user.locationProvince?.abbr}`,
          });
        }
      }
    }
  }, [locations, setValue, user]);

  const resetForm = useCallback(() => {
    reset({
      firstname: "",
      lastname: "",
      email: "",
      phoneNumber: "",
      preferredLocation: undefined,
      referredBy: "",
      message: "",
      consent: false,
    });
  }, [reset]);

  useEffect(() => {
    const defaultLocation = locations.find((x) => x.id === dealerId);
    if (defaultLocation) {
      setValue("preferredLocation", defaultLocation);
    } else if (!defaultLocation && !getValues("preferredLocation")) {
      setClosestLocationToCustomer();
    }
  }, [dealerId, locations, getValues, setClosestLocationToCustomer, setValue]);

  const [referralCampaign] =
    useSessionStorage<ReferralCampaign>("ReferralCampaign");
  const createLeadMutation = useCreateLeadMutation();
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState<boolean>(false);

  const handleFormSubmit: SubmitHandler<GeneralFormInput> = (
    formInput: GeneralFormInput
  ) => {
    let leadMessage = "";

    if (formInput?.message && formInput?.referredBy) {
      leadMessage = `Referred by: ${formInput.referredBy}\n\n${formInput.message}`;
    } else if (formInput?.message) {
      leadMessage = formInput.message;
    }

    const vinSolutionsId =
      locationDropdown && locationDropdown.hideLocations
        ? locationDropdown.vinsolutionsId
        : undefined;

    const { preferredLocation } = formInput;

    if (preferredLocation || vinSolutionsId) {
      createLeadMutation.mutate(
        {
          firstName: formInput.firstname,
          lastName: formInput.lastname,
          phoneNumber: formInput.phoneNumber,
          email: formInput.email,
          originUrl: document.location.href,
          leadDescription: campaignTag,
          dealerId: preferredLocation?.id,
          vinSolutionsDealerId: vinSolutionsId,
          message: leadMessage,
          userDataConsentGiven: true, // Form has a disclaimer, so we can assume consent is given.
          referralCampaignName: referralCampaign?.name,
          referralCampaignEmailId: referralCampaign?.emailId,
        },
        {
          onSuccess: () => {
            resetForm();
            setIsSubmitModalOpen(true);
            if (router.asPath.startsWith("/about")) {
              gtmPushData({
                event: "About-Us",
                descriptor: "Submit",
                firstName: formInput.firstname,
                lastName: formInput.lastname,
                email: formInput.email,
                phone: formInput.phoneNumber,
              });
            }
          },
        }
      );
    }
  };

  const [width, setWidth] = useState(0);

  const { width: w } = useWindowDimensions();

  useEffect(() => {
    setWidth(w);
  }, [w]);

  return (
    <div
      className="bg-white overflow-visible no-scrollbar relative bg-cover bg-center"
      style={GetBackgroundStyle(background)}
    >
      <LoadingSpinner isLoading={createLeadMutation.isPending} isOverlay />
      {isSubmitModalOpen && (
        <FormSubmissionModal
          isOpen={isSubmitModalOpen}
          setState={setIsSubmitModalOpen}
        />
      )}
      <div
        className={classNames({
          "bg-no-repeat": backgroundImageGroup?.image,
          "bg-[center_right_-16rem]":
            backgroundImageGroup?.imagePosition === "Right" &&
            width < sizeClass["2xl"] &&
            width >= sizeClass.xl,
          "bg-[center_right_-8rem]":
            backgroundImageGroup?.imagePosition === "Right" &&
            width < sizeClass["3xl"] &&
            width >= sizeClass["2xl"],
          "bg-[center_right_1rem]":
            backgroundImageGroup?.imagePosition === "Right" &&
            width < sizeClass["4xl"] &&
            width >= sizeClass["3xl"],
          "bg-[center_right_14rem]":
            backgroundImageGroup?.imagePosition === "Right" &&
            width >= sizeClass["4xl"],

          "bg-[center_left_-14rem]":
            backgroundImageGroup?.imagePosition === "Left" &&
            width < sizeClass["2xl"] &&
            width >= sizeClass.xl,
          "bg-[center_left_-6rem]":
            backgroundImageGroup?.imagePosition === "Left" &&
            width < sizeClass["3xl"] &&
            width >= sizeClass["2xl"],
          "bg-[center_left_2rem]":
            backgroundImageGroup?.imagePosition === "Left" &&
            width < sizeClass["4xl"] &&
            width >= sizeClass["3xl"],
          "bg-[center_left_16rem]":
            backgroundImageGroup?.imagePosition === "Left" &&
            width >= sizeClass["4xl"],
        })}
        style={
          width >= sizeClass.xl &&
          backgroundImageGroup?.image &&
          backgroundImageGroup.image.length > 0
            ? {
                backgroundImage: `url(${cloudflareImagesLoader({
                  src: backgroundImageGroup?.image[0].sourceUrl || "",
                  width: DEFAULT_FORM_SELECTOR_BACKGROUND_WIDTH,
                })})`,
              }
            : {}
        }
      >
        <div
          className={classNames(
            "relative max-w-page mx-auto lg:mx-0 xl:mx-auto flex items-center py-10 ",
            {
              "flex-col": backgroundImageGroup,
              "px-5 items-center lg:items-stretch":
                !backgroundImageGroup ||
                !backgroundImageGroup.image ||
                (backgroundImageGroup.image &&
                  backgroundImageGroup.image.length === 0),
              "lg:flex-row px-5 lg:pl-5 lg:pr-0 xl:px-5":
                backgroundImageGroup?.imagePosition === "Right" &&
                backgroundImageGroup.image &&
                backgroundImageGroup.image.length > 0,
              "lg:flex-row-reverse px-5 lg:pl-0 lg:pr-5 xl:px-5":
                backgroundImageGroup?.imagePosition === "Left" &&
                backgroundImageGroup.image &&
                backgroundImageGroup.image.length > 0,
            }
          )}
        >
          <div
            className={classNames("", {
              "flex-1 lg:basis-[60%] xl:basis-[50%]":
                backgroundImageGroup?.image,
            })}
          >
            <FormContentHeader title={title} description={description} />
            <form
              onSubmit={handleSubmit(handleFormSubmit)}
              className="my-5 flex flex-col gap-5 md:grid md:grid-cols-2"
            >
              <Form.FirstName
                fieldError={errors.firstname}
                {...register("firstname", { ...customerValidation.firstName })}
              />
              <Form.LastName
                fieldError={errors.lastname}
                {...register("lastname", customerValidation.lastName)}
              />
              <Form.Email
                fieldError={errors.email}
                {...register("email", customerValidation.email)}
              />
              <Form.Phone
                fieldError={errors.phoneNumber}
                {...register("phoneNumber", customerValidation.phoneNumber)}
              />
              {!locationDropdown.hideLocations && (
                <div className="col-span-2">
                  <Form.LocationPicker
                    name="preferredLocation"
                    fieldError={errors.preferredLocation as FieldError}
                    dealerDropdownLabel={t("common:preferred_location")}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t(
                          "common:preferred_location_form_error_required"
                        ),
                      },
                    }}
                  />
                </div>
              )}
              {referredBy.isEnabled && referredBy.referredByLabel && (
                <Form.Input
                  id="refferedBy"
                  label={referredBy.referredByLabel}
                  feedback={errors.referredBy?.message}
                  status={errors.referredBy ? "error" : "default"}
                  maxLength={40}
                  className="col-span-2"
                  {...register("referredBy", {
                    required: {
                      value: false,
                      message: t("common:form_error_required_referred_by"),
                    },
                  })}
                />
              )}
              {messageBox.isEnabled && messageBox.messageLabel && (
                <Form.TextArea
                  id="message"
                  label={messageBox.messageLabel}
                  feedback={errors.message?.message}
                  status={errors.message ? "error" : "default"}
                  className="col-span-2"
                  maxLength={200}
                  {...register("message", {
                    required: {
                      value: false,
                      message: t("common:form_error_required_message"),
                    },
                  })}
                />
              )}
              {consentCheckbox.isVisible &&
                (consentCheckbox.consentCheckboxLabel !== null ? (
                  <Form.Checkbox
                    label={
                      <div
                        className="cleanedParagraph"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: cleanParagraphTags(
                            consentCheckbox.consentCheckboxLabel as string
                          ),
                        }}
                      />
                    }
                    className="col-span-2"
                    {...register("consent", { required: true })}
                  />
                ) : (
                  <Form.Checkbox
                    label={t("common:general_form_agreement")}
                    className="col-span-2"
                    {...register("consent", { required: true })}
                  />
                ))}
              {errors.consent && (
                <Feedback status="error">
                  {t("common:form_error_required_consent")}
                </Feedback>
              )}
              <div className="flex flex-col gap-5 md:flex-row md:items-center md:col-span-2">
                <Button
                  isSubmit
                  buttonStyle="primary"
                  className="flex-shrink-0"
                  rightIcon={<ArrowRightIcon />}
                >
                  {t("common:submit")}
                </Button>
              </div>
              <div className="flex flex-col justify-center md:justify-start gap-5 md:flex-row md:items-center md:col-span-2">
                <Trans t={t} i18nKey="common:general_form_disclaimer">
                  <p className="italic text-xs">
                    <TextLink href="/privacy-policy">
                      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                      <a target="_blank" />
                    </TextLink>
                    <TextLink href="/terms-conditions">
                      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                      <a target="_blank" />
                    </TextLink>
                  </p>
                </Trans>
              </div>
            </form>
          </div>
          {backgroundImageGroup &&
            backgroundImageGroup.image &&
            backgroundImageGroup.image.length > 0 && (
              <FormSelectorBackgroundImage
                backgroundImageGroup={backgroundImageGroup}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default GeneralForm;
