import { createContext, Dispatch, SetStateAction } from "react";

export type StripeMode = "payment" | "setup" | "subscription" | undefined;

export type StripeContextValue = {
  clientSecret: string | undefined;
  setClientSecret: Dispatch<SetStateAction<string | undefined>>;
  stripeElementsReady: boolean;
  mode: StripeMode;
};

const StripeContext = createContext<StripeContextValue>({
  stripeElementsReady: false,
} as StripeContextValue);

export default StripeContext;
