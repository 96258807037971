import Modal from "@common/components/Modal";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import useSourceInfoFromQuery from "@modules/query-string/hooks/useSourceInfoFromQuery";
import useAftermarketPurchaseFromQuery from "@modules/purchase/hooks/useAftermarketPurchaseFromQuery";
import useVehicleDisplayFuelTypes from "@modules/vehicle/hooks/useVehicleDisplayFuelTypes";
import translate from "@common/helpers/translate";
import { useRouter } from "next/router";
import { getCondensedCbbVehicleName } from "@modules/vehicle/hooks/useCbbVehicleLookup";
import { useNullableNumberMask } from "@common/hooks/useMask";
import { quoteMethods } from "@common/enums/QuoteMethod";
import { getProvinceByAbbr } from "@common/types/Province";
import { provinceFilters } from "@modules/purchase/hooks/useEligibleProvinces";
import useProvincesStatusBySourceList from "@modules/purchase/hooks/useProvincesStatusBySourceList";
import {
  WarrantyInstantQuoteModalMode,
  WarrantyInstantQuoteModalModeName,
} from "./types/WarrantyInstantQuoteModalMode";
import WarrantyInstantQuoteVehicleInfo from "./WarrantyInstantQuoteVehicleInfo";
import WarrantyInstantQuotePersonalInfo from "./WarrantyInstantQuotePersonalInfo";
import WarrantyInstantQuoteGenerated from "./WarrantyInstantQuoteGenerated";
import { WarrantyInstantQuoteFormProps } from "./types/WarrantyInstantQuoteFormProps";

type Props = {
  handleModalClose(): void;
  showModal: boolean;
  modeState: [
    WarrantyInstantQuoteModalMode,
    Dispatch<SetStateAction<WarrantyInstantQuoteModalMode>>
  ];
};

const WarrantyInstantQuoteModal = ({
  handleModalClose,
  showModal,
  modeState,
}: Props) => {
  const { t } = useTranslation(["common", "purchase"]);
  const [mode, setMode] = modeState;

  const router = useRouter();
  const { locale: rlocale } = router;
  const locale = rlocale || "en";

  const switchMode = useCallback(
    (newMode: WarrantyInstantQuoteModalMode) => {
      setMode(newMode);
    },
    [setMode]
  );

  const aftermarketPurchaseFromQuery = useAftermarketPurchaseFromQuery();
  const { source: querySource } = useSourceInfoFromQuery();

  const {
    firstName,
    lastName,
    email,
    phone,
    province: buyerProvince,
    vin: buyerVin,
    mileage,
    year,
    make,
    model,
    trim,
    fuelType,
  } = aftermarketPurchaseFromQuery;

  const provincesList = useProvincesStatusBySourceList(
    querySource,
    provinceFilters.isAftermarketEnabled
  );

  const { maskPipe: numberMaskPipe } = useNullableNumberMask();

  const { fuelTypes } = useVehicleDisplayFuelTypes();
  const fuelTypeOptions: { id: number; name: string }[] | null = useMemo(
    () =>
      fuelTypes.length > 0
        ? fuelTypes.map(({ id, name }) => ({
            id,
            name: translate(name, locale),
          }))
        : null,
    [fuelTypes, locale]
  );

  const methods = useForm<WarrantyInstantQuoteFormProps>({
    defaultValues: {
      firstName: firstName ?? "",
      lastName: lastName ?? "",
      email: email ?? "",
      phone: phone ?? "",
      province: buyerProvince
        ? getProvinceByAbbr(
            provincesList.find((p) => p.abbr === buyerProvince && p.isEnabled)
              ?.abbr ?? ""
          )
        : undefined,
      vin: buyerVin ?? "",
      odometer: mileage ? numberMaskPipe(mileage.toString()) : "",
      consent: false,
      vehicleDetails:
        year && make && model && model !== "Other" && model !== "Autre"
          ? {
              year,
              make,
              model,
              trim: trim ?? undefined,
              name: getCondensedCbbVehicleName(year, make, model, trim),
            }
          : undefined,
      fuelType: fuelType
        ? fuelTypeOptions?.find((ft) => ft.name === fuelType)
        : undefined,
      quoteMethod: quoteMethods[0],
    },
  });

  const setHeading = () => {
    let modalContentHeading = "";
    switch (mode.name) {
      case WarrantyInstantQuoteModalModeName.vehicleInfo: {
        modalContentHeading = t("purchase:tell_us_about_your_ride");
        break;
      }
      case WarrantyInstantQuoteModalModeName.personalInfo: {
        modalContentHeading = t("purchase:tell_us_about_yourself");
        break;
      }
      default:
    }

    return modalContentHeading;
  };

  return (
    <Modal
      key="warranty-instant-quote-modal"
      isOpen={showModal}
      onClose={() => {
        handleModalClose();
      }}
      alignment="center"
      buttonDisplay="inline"
      title={t("purchase:instant_quote")}
      contentHeading={setHeading()}
    >
      <div>
        {/* eslint-disable react/jsx-props-no-spreading */}
        <FormProvider {...methods}>
          <div>
            {mode?.name === WarrantyInstantQuoteModalModeName.vehicleInfo && (
              <WarrantyInstantQuoteVehicleInfo
                onChangeMode={switchMode}
                source={querySource}
              />
            )}
            {mode?.name === WarrantyInstantQuoteModalModeName.personalInfo && (
              <WarrantyInstantQuotePersonalInfo onChangeMode={switchMode} />
            )}
            {mode?.name ===
              WarrantyInstantQuoteModalModeName.quoteGenerated && (
              <WarrantyInstantQuoteGenerated />
            )}
          </div>
        </FormProvider>
      </div>
    </Modal>
  );
};

export default WarrantyInstantQuoteModal;
