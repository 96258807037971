import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { VehicleSortOptions } from "@modules/vehicle/types/VehicleSortOptions";
import { gtmPushData } from "@common/helpers/gtm";
import CoreLayout from "@layouts/core/components/CoreLayout";
import { useTranslation } from "next-i18next";
import { ArrowRightIcon, CloseIcon, MenuIcon } from "@common/components/icons";
import useUser from "@modules/user/hooks/useUser";
import Button from "@common/components/Button";
import { ReverseGeocodeAddress } from "@modules/location/types/ReverseGeocodeAddress";
import { useRouter } from "next/router";
import VehicleFilterContext, {
  VehicleFilterContextState,
} from "@modules/vehicle/VehicleFilterContext";
import LocationModalContext, {
  LocationModalContextState,
} from "@modules/location/LocationModalContext";
import useFilterReducer from "@modules/vehicle/hooks/useFilterReducer";
import classNames from "classnames";
import { FilterActions } from "@modules/vehicle/types/vehicleFilter";
import { removeQueryParams } from "@modules/core/routing/routingHelpers";
import Snackbar from "@common/components/Snackbar";
import isVehicleListingPage from "@common/helpers/isVehicleListingPage";
import {
  createAccountMode,
  LoginModalMode,
  loginMode,
} from "@modules/user/types/LoginModalMode";
import {
  CreditAppModalMode,
  introductionMode,
} from "@modules/credit-app/types/CreditAppModalMode";
import CreditAppModalContext, {
  CreditAppModalContextState,
} from "@modules/credit-app/CreditAppModalContext";
import useCreditAppInputsReducer from "@modules/credit-app/hooks/useCreditAppInputsReducer";
import { InputActions } from "@modules/credit-app/types/creditAppInput";
import SaveToFavouritesModalContext, {
  SaveToFavouritesModalContextState,
} from "@modules/vehicle/SaveToFavouritesModalContext";
import useLocalStorage from "@common/hooks/useLocalStorage";
import { isTransitionComplete } from "@common/helpers/modalHelper";
import CustomerAvatar from "@common/components/CustomerAvatar";
import useUnsubscribeSavedSearchesMutation from "@modules/user/hooks/useUnsubscribeSavedSearchesMutation";
import { Event as HotjarEvent, hotjar } from "@modules/core/hotjar/types";
import GoogleMapsWrapper from "@modules/location/helpers/GoogleMapsWrapper";
import { DynamicLocationModal } from "@modules/location/components";
import { DynamicSaveToFavouritesModal } from "@modules/vehicle/components";
import { DynamicUnsubscribeSavedSearchesModal } from "@modules/user/components";
import { DynamicCreditAppModal } from "@modules/credit-app/components";
import Gubagoo from "@common/components/Gubagoo";
import { getPageSettings } from "@common/helpers/pageSettingsHelper";
import Link from "next/link";
import LocalImage from "@common/components/LocalImage";
import RightRideLogo from "@public/images/logo-rightride-autocan.png";
import LoginFlowWrapper from "@components/LoginFlowWrapper";
import GravityFormsPopupModal from "@modules/cms/components/GravityFormsPopupModal";
import GravityFormsPopupModalEnum from "@modules/cms/constants/GravityFormsPopupModalEnum";
import { BaseVehicleDetail } from "@modules/vehicle/types/VehicleDetail";
import FavouritesLink from "./FavouritesLink";
import VehicleSearch from "./VehicleSearch";
import VehiclesFilterButton from "./VehiclesFilterButton";
import NavBar from "./NavBar";
import Footer from "./Footer";

type Props = {
  children: ReactNode[] | ReactNode;
  onLogin?: () => void;
};

const pageAtTopOffset = 120;
const modalTransitionDuration = 301;

const PublicLayout = ({ children, onLogin }: Props) => {
  const { t } = useTranslation(["common", "indexPage"]);
  const { user } = useUser();
  const router = useRouter();
  const [vehicleFilters, vehicleFilterDispatch] = useFilterReducer();
  const [creditAppInputs, creditAppDispatch] = useCreditAppInputsReducer();
  const showVehicleFilterState = useState<boolean>(false);
  const [, setShowVehicleFilter] = showVehicleFilterState;
  const [showStickySearch, setShowStickySearch] = useState<boolean>();
  const vehicleFiltersState: VehicleFilterContextState = useMemo(
    () => ({
      vehicleFilters,
      dispatch: vehicleFilterDispatch,
      showVehicleFilterState,
    }),
    [vehicleFilters, vehicleFilterDispatch, showVehicleFilterState]
  );
  const [ignoreQueryChanges, setIgnoreQueryChanges] = useState(false);
  const pageSettings = getPageSettings(children);

  const { unsubscribeSavedSearches, location } = router.query;

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showCreditAppModal, setShowCreditAppModal] = useState(false);

  const [creditAppModalModeState, setCreditAppModalModeState] =
    useState<CreditAppModalMode>(introductionMode);
  const [creditAppVehicleDetail, setCreditAppVehicleDetail] =
    useState<BaseVehicleDetail>();

  const [creditIdState, setCreditIdState] = useState<string>("");
  const [initialLoaded, setInitialLoaded] = useState<boolean>(false);

  const creditAppModalContextState: CreditAppModalContextState =
    useMemo<CreditAppModalContextState>(
      () => ({
        setCreditAppShowModal: setShowCreditAppModal,
        creditAppInputs,
        creditAppDispatch,
        creditAppIdState: [creditIdState, setCreditIdState],
        initialLoaded: [initialLoaded, setInitialLoaded],
        creditAppModeState: [
          creditAppModalModeState,
          setCreditAppModalModeState,
        ],
        vehicleDetail: [creditAppVehicleDetail, setCreditAppVehicleDetail],
      }),
      [
        creditAppDispatch,
        creditAppInputs,
        creditAppModalModeState,
        creditAppVehicleDetail,
        creditIdState,
        initialLoaded,
      ]
    );

  const [showLocationModal, setShowLocationModal] = useState(false);
  const [userLocation, setUserLocation] =
    useState<ReverseGeocodeAddress | null>();
  useEffect(() => {
    if (
      !userLocation ||
      (userLocation?.city !== user?.locationCity &&
        userLocation?.province !== user?.locationProvince?.name)
    ) {
      setUserLocation(
        user?.locationCity && user?.locationProvince
          ? {
              city: user?.locationCity,
              province: t(user?.locationProvince?.name),
              provinceAbbr: user?.locationProvince?.abbr,
              formattedAddress: "",
              country: "",
            }
          : null
      );
    }
  }, [userLocation, user, t]);

  const handleOpenLocationModal = () => {
    gtmPushData({
      event: "UserLocation",
      element: "Action",
      descriptor: "Set-Location",
    });
    setShowLocationModal(true);
  };
  const handleCloseLocationModal = () => {
    gtmPushData({
      event: "UserLocation",
      element: "Close",
    });
    setShowLocationModal(false);
  };

  const locationModalContextState: LocationModalContextState =
    useMemo<LocationModalContextState>(
      () => ({
        showLocationModal,
        handleOpenLocationModal,
        handleCloseLocationModal,
        handleAddressChange: (
          customerAddress: ReverseGeocodeAddress | null
        ) => {
          setUserLocation(customerAddress);

          if (customerAddress) {
            vehicleFilterDispatch({
              type: FilterActions.AddSortByFilter,
              payload: VehicleSortOptions.bestMatch,
            });
          }
        },
        userLocation,
        setUserLocation,
      }),
      [showLocationModal, vehicleFilterDispatch, userLocation]
    );

  const [hasSavedFavourites, setHasSavedFavourites] =
    useLocalStorage<boolean>("hasSavedFavourites");
  const showSaveToFavouritesModalState = useState<boolean>(false);
  const savedFavouritesLimitExceededState = useState<boolean>(false);
  const [showSaveToFavouritesModal, setShowSaveToFavouritesModal] =
    showSaveToFavouritesModalState;
  const [isSavedFavouritesLimitExceeded] = savedFavouritesLimitExceededState;
  const saveToFavouritesModalContextState: SaveToFavouritesModalContextState =
    useMemo<SaveToFavouritesModalContextState>(
      () => ({
        showModalState: showSaveToFavouritesModalState,
        limitExceededState: savedFavouritesLimitExceededState,
      }),
      [savedFavouritesLimitExceededState, showSaveToFavouritesModalState]
    );

  const [showUnsubscribeSavedSearches, setShowUnsubscribeSavedSearches] =
    useState<boolean>(false);
  const unsubscribeSavedSearchesMutation =
    useUnsubscribeSavedSearchesMutation();

  useEffect(() => {
    if (!ignoreQueryChanges) {
      if (unsubscribeSavedSearches) {
        setShowUnsubscribeSavedSearches(true);
        setIgnoreQueryChanges(true);
      }
    }
  }, [ignoreQueryChanges, router, t, user, unsubscribeSavedSearches]);

  useEffect(() => {
    if (location && router.asPath.includes("/financing")) {
      setShowCreditAppModal(true);
    }
  }, [location, router]);

  const handleCreditAppModalClose = () => {
    setCreditAppModalModeState(introductionMode);
    setCreditIdState("");
    setInitialLoaded(false);
    creditAppDispatch({
      type: InputActions.ResetInputs,
      payload: undefined,
    });
    setShowCreditAppModal(false);
  };

  const handleUnsubscribeSavedSearchModalClose = () => {
    setShowUnsubscribeSavedSearches(false);
    removeQueryParams(router, ["unsubscribeSavedSearches"]);
  };

  const handleUnsubscribeSavedSearch = () => {
    unsubscribeSavedSearchesMutation.mutate(
      unsubscribeSavedSearches as string,
      {
        onSuccess: () => {
          Snackbar.Pop({
            message: t("indexPage:we_turned_off_inv_alerts"),
            closeButtonText: t("common:okay"),
          });
        },
        onError: () => {
          Snackbar.Pop({
            message: t("indexPage:email_link_expired"),
            closeButtonText: t("common:okay"),
          });
        },
      }
    );
  };

  const favouritesLink = () => {
    return (
      <FavouritesLink
        href="/profile/saved-vehicles"
        className="self-center"
        count={user?.favouriteVehicles?.length || 0}
      />
    );
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });

    const onScroll = () => {
      setShowStickySearch(false);
      if (window.scrollY > pageAtTopOffset) {
        setShowStickySearch(true);
      }
    };

    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    if (user == null) return;
    gtmPushData({ user_id: user.id });

    if (!user?.firstName) return;
    hotjar.identify(user.id, {
      firstName: user.firstName,
    });
  }, [user]);

  if (
    user?.favouriteVehicles?.length === 1 &&
    !hasSavedFavourites &&
    !user.isLoggedIn
  ) {
    setHasSavedFavourites(true);
    setShowSaveToFavouritesModal(true);
  }

  const sideNavBtnRefStickySearch = useRef(null);
  const [showSideNav, setShowSideNav] = useState<boolean>(false);
  const [loginModalMode, setLoginModalMode] =
    useState<LoginModalMode>(loginMode);

  return (
    <CoreLayout>
      <Gubagoo />
      {user && (
        <GravityFormsPopupModal
          origin={GravityFormsPopupModalEnum.All}
          user={user}
        />
      )}
      <LoginFlowWrapper
        isLoginModalOpen={showLoginModal}
        onLoginModalClose={() => setShowLoginModal(false)}
        onLogin={onLogin}
        loginModalMode={loginModalMode}
      >
        <SaveToFavouritesModalContext.Provider
          value={saveToFavouritesModalContextState}
        >
          <CreditAppModalContext.Provider value={creditAppModalContextState}>
            <VehicleFilterContext.Provider value={vehicleFiltersState}>
              <LocationModalContext.Provider value={locationModalContextState}>
                {pageSettings.hideHeader && pageSettings.showLogo && (
                  <div className="flex flex-rows gap-4 justify-center px-8 py-4">
                    <div className="grow basis-1">
                      <Link href="/">
                        <LocalImage
                          className="mx-auto"
                          width="173"
                          height="39"
                          src={RightRideLogo}
                          alt="RightRide Logo"
                          priority
                        />
                      </Link>
                    </div>
                  </div>
                )}
                {!pageSettings.hideHeader && (
                  <div className="inline">
                    <NavBar
                      user={user}
                      showSideNav={showSideNav}
                      sideNavBtnRefStickySearch={sideNavBtnRefStickySearch}
                      setShowSideNav={setShowSideNav}
                      setShowLoginModal={setShowLoginModal}
                      setLoginModalMode={setLoginModalMode}
                    />
                    <div
                      className={classNames(
                        "2md:hidden sticky top-0 pr-2 pl-4 sm:pl-6 lg:pl-8 py-2 z-[5] bg-background-light-100 shadow-elevation-00",
                        {
                          hidden: !showStickySearch,
                        }
                      )}
                    >
                      <div className="flex items-center gap-1">
                        <div className="flex-auto">
                          <VehicleSearch
                            recentSearches={user?.recentSearches}
                            savedSearches={user?.savedSearches}
                          />
                        </div>
                        {isVehicleListingPage(router.pathname) && (
                          <VehiclesFilterButton
                            onClick={() => setShowVehicleFilter(true)}
                            count={
                              vehicleFilters.filters.filter(
                                (filter) => filter.key !== "sort"
                              )?.length
                            }
                          />
                        )}
                        <Button
                          fill="link"
                          spacing="tight-hug"
                          size="small"
                          className="px-[.1rem]"
                          onClick={() => setShowSideNav(!showSideNav)}
                          ref={sideNavBtnRefStickySearch}
                        >
                          {!showSideNav ? (
                            <MenuIcon fontSize="2em" fill="#293EDD" />
                          ) : (
                            <CloseIcon fontSize="2em" fill="#293EDD" />
                          )}
                        </Button>
                      </div>
                    </div>
                    <div className="pb-2 bg-background-light-100 px-4 sm:px-6 lg:px-8">
                      <div className="flex flex-row items-center justify-between gap-3">
                        <div className="flex-auto">
                          {!showStickySearch && (
                            <VehicleSearch
                              savedSearches={user?.savedSearches}
                              recentSearches={user?.recentSearches}
                            />
                          )}
                        </div>
                        <div className="hidden 2md:flex md:gap-3">
                          {favouritesLink()}
                          {user && user.isLoggedIn ? (
                            <Button
                              fill="link"
                              spacing="tight-hug"
                              className="border border-gray-50"
                              rightIcon={<ArrowRightIcon />}
                              onClick={() => {
                                router.push("/profile");
                              }}
                            >
                              <div className="flex items-center text-black max-w-[10rem] xl:max-w-[14rem]">
                                <CustomerAvatar
                                  customer={user}
                                  size="sm"
                                  isCurrentUser
                                  isProfileAvatar
                                />
                                <p className="text-ellipsis whitespace-nowrap overflow-hidden pl-3">
                                  <span>{user.firstName}</span>{" "}
                                  <span data-hj-suppress>{user.lastName}</span>
                                </p>
                              </div>
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                hotjar.event(HotjarEvent.UserLogin);
                                setLoginModalMode(loginMode);
                                setShowLoginModal(true);
                              }}
                              rightIcon={<ArrowRightIcon />}
                              fill="outline"
                              buttonStyle="transparent"
                              className="focus:outline-none focus-visible:shadow-elevation-03"
                            >
                              {t("common:log_in_or_sign_up")}
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {children}
                {showLocationModal && (
                  <GoogleMapsWrapper>
                    <DynamicLocationModal showLocationModal />
                  </GoogleMapsWrapper>
                )}
              </LocationModalContext.Provider>
            </VehicleFilterContext.Provider>
            {showCreditAppModal && (
              <DynamicCreditAppModal
                modeState={[
                  creditAppModalModeState,
                  setCreditAppModalModeState,
                ]}
                creditAppIdState={[creditIdState, setCreditIdState]}
                showModal={showCreditAppModal}
                handleModalClose={handleCreditAppModalClose}
                vehicle={creditAppVehicleDetail}
              />
            )}
            {showSaveToFavouritesModal && (
              <DynamicSaveToFavouritesModal
                open={showSaveToFavouritesModal}
                onClose={() => setShowSaveToFavouritesModal(false)}
                onSubmit={() => {
                  setShowSaveToFavouritesModal(false);
                  setLoginModalMode(createAccountMode);

                  // HACK: Multiple dialog scrolling bug.
                  // https://github.com/tailwindlabs/headlessui/issues/1744#issuecomment-1208079384
                  setTimeout(
                    () => isTransitionComplete(() => setShowLoginModal(true)),
                    modalTransitionDuration
                  );
                }}
                limitExceeded={isSavedFavouritesLimitExceeded}
                onLogin={() => {
                  setShowSaveToFavouritesModal(false);
                  setLoginModalMode(loginMode);
                  setTimeout(
                    () => isTransitionComplete(() => setShowLoginModal(true)),
                    modalTransitionDuration
                  );
                }}
              />
            )}
            {showUnsubscribeSavedSearches && (
              <DynamicUnsubscribeSavedSearchesModal
                onModalClose={() => handleUnsubscribeSavedSearchModalClose()}
                onUnsubscribe={() => handleUnsubscribeSavedSearch()}
                showModal={showUnsubscribeSavedSearches}
              />
            )}
          </CreditAppModalContext.Provider>
        </SaveToFavouritesModalContext.Provider>
      </LoginFlowWrapper>

      {!pageSettings.hideFooter && <Footer />}
    </CoreLayout>
  );
};

export default PublicLayout;
