import api from "@modules/api/api";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import DealStatusEnum from "@common/enums/DealStatusEnum";
import purchaseQueryKeys from "../purchaseQueryKeys";
import { Purchase } from "../types/Purchase";

const getPurchasesByDealIds = (dealIds: string[]) => {
  return api.get<Purchase[]>("api/purchases/deals", {
    searchParams: {
      dealIds: dealIds.map((dealId) => dealId).join(","),
    },
  });
};

export default function useGetPurchasesByDealIds(dealIds: string[]) {
  const {
    data: purchases,
    isLoading,
    isFetching,
    isStale,
    refetch,
  } = useQuery({
    queryKey: purchaseQueryKeys.purchase(dealIds),
    queryFn: () => getPurchasesByDealIds(dealIds),
    enabled: dealIds.length > 0,
    staleTime: 2 * 60 * 1000, // 2 minutes stale time
    meta: { overrideDefaultErrorHandling: true },
  });

  const activePurchases = useMemo(() => {
    return (
      purchases?.filter(
        (purchase) =>
          purchase.dealStatusId !== DealStatusEnum.Cancelled &&
          purchase.dealStatusId !== DealStatusEnum.Abandoned
      ) || []
    );
  }, [purchases]);

  return {
    allPurchases: purchases || [],
    activePurchases,
    isLoading,
    isFetching,
    isStale,
    refetch,
  };
}
