import classNames from "classnames";
import { ReactNode, forwardRef, ComponentPropsWithoutRef } from "react";

export type ButtonFill = "filled" | "outline" | "link";
export type ButtonStyle =
  | "primary"
  | "secondary"
  | "primary-light"
  | "danger"
  | "white"
  | "dark"
  | "filter"
  | "transparent"
  | "borderless";
export type ButtonSpacing =
  | "hug"
  | "tight-hug"
  | "fixed"
  | "no-padding"
  | "no-padding-x"
  | "tight-hug-icon";

export type ButtonProps = {
  id?: string;
  children?: React.ReactNode;
  isSubmit?: boolean;
  fill?: ButtonFill;
  buttonStyle?: ButtonStyle;
  size?: "extra-large" | "large" | "small";
  spacing?: ButtonSpacing;
  border?: "normal" | "thick" | "none";
  rightIcon?: ReactNode;
  leftIcon?: ReactNode;
  iconClassName?: string;
  className?: string;
  active?: boolean;
  childWidth?: "full" | "auto";
  isInteractive?: boolean;
} & ComponentPropsWithoutRef<"button">;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      id = undefined,
      children,
      isSubmit = false,
      fill = "filled",
      buttonStyle = "primary",
      size = "large",
      spacing = "hug",
      border = "normal",
      disabled = false,
      rightIcon = undefined,
      leftIcon = undefined,
      iconClassName = "",
      className = "",
      active = false,
      childWidth = "auto",
      isInteractive = true,
      ...props
    }: ButtonProps,
    ref
  ) => {
    let backgroundColor;
    let textColor;
    let textSize;
    let padding;
    let borderClass = "";
    let borderRadius = "rounded-button";
    let childWidthClass;
    let childMarginClass = "mx-2";

    if (childWidth === "auto") {
      childWidthClass = "w-auto";
    } else if (childWidth === "full") {
      childWidthClass = "w-full";
    }

    if (disabled) {
      textColor = "text-black text-opacity-38";
      if (fill === "outline") {
        backgroundColor = "bg-white";
        borderClass = classNames("border-solid border-light-100", {
          border: border === "normal",
          "border-2": border === "thick",
        });
      } else if (fill === "link") {
        backgroundColor = "bg-transparent";
      } else {
        backgroundColor = "bg-gray-25";
      }
    } else {
      switch (fill) {
        case "filled":
          switch (buttonStyle) {
            case "primary":
              backgroundColor = classNames(
                "bg-primary-600 hover:bg-primary-700 active:bg-primary-600",
                // TODO: we need to standardize the colors for Kijiji and AutoCan. We don't have DS for those two yet.
                "kijiji:hover:bg-primary-300 kijiji:active:bg-primary-700",
                "autocan:bg-primary-600 autocan:hover:bg-primary-700 autocan:active:bg-primary-800"
              );
              textColor = "text-white";
              break;
            case "dark":
              backgroundColor = classNames(
                "bg-primary-900 hover:bg-primary-950 active:bg-primary-900",
                "kijiji:hover:bg-primary-300 kijiji:active:bg-primary-700"
              );
              textColor = "text-white";
              break;
            case "secondary":
            case "primary-light":
              backgroundColor =
                "bg-secondary-50 hover:bg-secondary-100 active:bg-secondary-50";
              textColor = "text-primary";
              break;
            case "danger":
              backgroundColor =
                "bg-error-600 hover:bg-error-700 active:bg-error-800";
              textColor = "text-white";
              break;
            // TODO: "white" not part of Design System. This seems to equivalent to elevation?
            case "white":
              backgroundColor =
                "shadow-elevation-00 bg-white hover:bg-secondary-100";
              textColor = "text-gray-900";
              break;
            default:
              throw Error("Style not supported");
          }
          break;
        case "outline":
          switch (buttonStyle) {
            case "primary":
              backgroundColor = classNames(
                "bg-white hover:bg-button-background-primary-outline_hover active:bg-white",
                "kijiji:active:bg-primary-300",
                "autocan:active:bg-primary-300"
              );
              textColor =
                "text-primary-600 hover:text-primary-700 kijiji:active:text-white";
              borderClass = classNames(
                "border-solid border-primary-600 hover:border-primary-700 autocan:border",
                {
                  border: border === "normal",
                  "border-2": border === "thick",
                }
              );
              break;
            case "dark":
              backgroundColor =
                "bg-primary-900 hover:bg-primary-950 active:bg-primary-900";
              textColor = "text-white";
              borderClass = classNames("border-solid border-white", {
                border: border === "normal",
                "border-2": border === "thick",
              });
              break;
            case "danger":
              backgroundColor = "bg-white hover:bg-error-25 active:bg-error-50";
              textColor = "text-error-600 active:text-error-900";
              borderClass = classNames("border-solid border-error-600", {
                border: border === "normal",
                "border-2": border === "thick",
              });
              break;
            case "transparent":
              backgroundColor =
                "bg-transparent hover:bg-background-light-300 active:bg-primary-light";
              textColor = "text-primary";
              borderClass = classNames("border-solid border-primary", {
                border: border === "normal",
                "border-2": border === "thick",
              });
              break;
            case "filter":
              backgroundColor = `bg-white ring-primary-bright justify-center lg:hover:border-primary-bright focus:outline-none focus-visible:border-primary-bright flex flex-row items-center w-full h-full border select-none py-2${
                active
                  ? " border-primary-bright ring-1 ring-offset-0 text-primary [&>svg]:text-primary"
                  : " border-gray-50"
              }`;
              textColor = "text-gray-900";
              borderRadius = "rounded-[4px]";
              break;
            case "secondary":
              throw Error("Secondary outline style not supported");
            default:
              throw Error("Style not supported");
          }
          break;
        case "link":
          switch (buttonStyle) {
            case "primary":
              backgroundColor =
                "bg-transparent hover:bg-primary-25 active:bg-transparent";
              textColor = "text-primary";
              break;
            case "danger":
              backgroundColor =
                "bg-transparent hover:bg-error-25 active:bg-error-100";
              textColor = "text-error-600 active:text-error-900";
              break;
            case "borderless":
              backgroundColor = "bg-transparent";
              borderRadius = "border-0";
              textColor = "caption-1 gray-900";
              break;
            case "transparent":
              backgroundColor = "bg-transparent";
              borderRadius = "border-0";
              textColor = "text-white hover:text-primary active:text-white";
              break;
            default:
              throw Error(`Style (${buttonStyle}) not supported`);
          }
          break;
        default:
          throw Error(`Fill (${fill}) not supported`);
      }
    }

    switch (size) {
      case "extra-large":
        textSize = `leading-5 text-lg font-medium`;
        switch (spacing) {
          case "hug":
            padding = `py-4 px-8 min-w-[8rem]`;
            break;
          case "tight-hug":
            padding = "py-3 px-2";
            break;
          case "tight-hug-icon":
            padding = "py-3 px-2";
            childMarginClass = "mx-0";
            break;
          case "fixed":
            padding = "py-3 w-full flex justify-center";
            break;
          case "no-padding":
            padding = "p-0 flex justify-center";
            break;
          case "no-padding-x":
            padding = "py-3 px-0";
            break;
          default:
            throw Error("Spacing not supported");
        }
        break;
      case "large":
        textSize = `leading-4 text-base font-medium`;
        switch (spacing) {
          case "hug":
            padding = `py-4 px-4 min-w-[8rem]`;
            break;
          case "tight-hug":
            padding = "py-3 px-2";
            break;
          case "tight-hug-icon":
            padding = "py-3 px-2";
            childMarginClass = "mx-0";
            break;
          case "fixed":
            padding = "py-3 w-full flex justify-center";
            break;
          case "no-padding":
            padding = "p-0 flex justify-center";
            break;
          case "no-padding-x":
            padding = "py-3 px-0";
            break;
          default:
            throw Error("Spacing not supported");
        }
        break;
      case "small":
        textSize = "caption-1";
        switch (spacing) {
          case "hug":
          case "fixed":
            padding = "py-2 px-4";
            break;
          case "tight-hug":
            padding = "py-2 px-2";
            break;
          case "tight-hug-icon":
            padding = "py-2 px-2";
            childMarginClass = "mx-0";
            break;
          case "no-padding":
            padding = "p-0 flex justify-center";
            break;
          case "no-padding-x":
            padding = "py-2 px-0";
            break;
          default:
            throw Error("Spacing not supported");
        }
        break;
      default:
        throw Error("Size not supported");
    }

    const buttonContent = (
      <div className="w-full flex flex-row justify-center items-center">
        <div className={iconClassName}>{leftIcon}</div>
        <div className={classNames(childMarginClass, childWidthClass)}>
          {children}
        </div>
        <div className={iconClassName}>{rightIcon}</div>
      </div>
    );

    const btnClassNames = classNames(
      "inline-flex items-center justify-center transition-colors",
      borderRadius,
      className,
      backgroundColor,
      textColor,
      textSize,
      padding,
      borderClass
    );

    return isInteractive ? (
      <button
        id={id}
        type={isSubmit ? "submit" : "button"}
        className={btnClassNames}
        disabled={disabled}
        ref={ref}
        {...props}
      >
        <div className="w-full flex flex-row justify-center items-center font-button-label">
          <div className={iconClassName}>{leftIcon}</div>
          <div className={classNames(childMarginClass, childWidthClass)}>
            {children}
          </div>
          <div className={iconClassName}>{rightIcon}</div>
        </div>
      </button>
    ) : (
      <div id={id} className={btnClassNames}>
        {buttonContent}
      </div>
    );
  }
);

export default Button;
