import DealTypeEnum from "@common/enums/DealTypeEnum";
import { User } from "@modules/user/types/user";

const getUserDealIdsByType = (
  user: User | undefined,
  dealType: DealTypeEnum
) => {
  return user?.deals
    ? user.deals.filter((d) => d.type.id === dealType).map((d) => d.id)
    : [];
};

export default getUserDealIdsByType;
