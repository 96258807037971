import api from "@modules/api/api";
import vehicleQueryKeys from "@modules/vehicle/vehicleQueryKeys";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getGuestUser, User } from "../types/user";
import userQueryKeys from "../userQueryKeys";

type SavelocationRequest = {
  latitude: number;
  longitude: number;
  city: string;
  province: string;
};

const postLocation = async (
  saveLocationRequest: SavelocationRequest
): Promise<void> => {
  await api.rawPost(`api/users/save-location`, {
    json: {
      latitude: saveLocationRequest.latitude,
      longitude: saveLocationRequest.longitude,
      city: saveLocationRequest.city,
      provinceAbbr: saveLocationRequest.province,
    },
  });
};

export default function useSaveLocationMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postLocation,
    onSuccess: (_response, request) => {
      // Price-breakdowns have a tax component that is influenced by user location
      queryClient.invalidateQueries({
        queryKey: vehicleQueryKeys.pricingBreakdowns(),
      });
      queryClient.invalidateQueries({
        queryKey: vehicleQueryKeys.lists(),
      });
      queryClient.setQueryData(
        userQueryKeys.user(),
        (data: User | undefined) => {
          const user = data || getGuestUser();

          return {
            ...user,
            latitude: request.latitude,
            longitude: request.longitude,
            locationCity: request.city,
            locationProvince: {
              abbr: request.province,
            },
          };
        }
      );
    },
  });
}
