import { NameId } from "@common/types/NameId";

enum SourceEnum {
  RightRide = 1,
  Kijiji = 2,
  RightRideDigital = 3,
  ACDealer = 4,
}

export const sources: NameId[] = [
  {
    name: "RightRide",
    id: SourceEnum.RightRide.toString(),
  },
  {
    name: "Kijiji",
    id: SourceEnum.Kijiji.toString(),
  },
  {
    name: "RightRide Digital",
    id: SourceEnum.RightRideDigital.toString(),
  },
  {
    name: "AC-Dealer",
    id: SourceEnum.ACDealer.toString(),
  },
].sort((a, b) => a.name.localeCompare(b.name));

export default SourceEnum;
