import classNames from "classnames";
import {
  ComponentPropsWithoutRef,
  forwardRef,
  ReactNode,
  useState,
} from "react";
import Feedback from "./Feedback";
import { Status } from "./types";

type Props = {
  label: ReactNode | ReactNode[];
  checkboxStyle?: "regular" | "bordered";
  status?: Status;
  feedback?: ReactNode;
} & ComponentPropsWithoutRef<"input">;

// eslint-disable-next-line no-underscore-dangle
const _Checkbox = forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      label,
      disabled,
      className,
      checkboxStyle = "regular",
      status = "default",
      feedback = "",
      ...props
    },
    ref
  ) => {
    const [onFocus, setOnFocus] = useState(false);

    return (
      <>
        <label
          className={classNames(
            "flex items-top cursor-pointer text-text-light-100 body-3 ",
            {
              "my-2 flex-row justify-between w-full peer-focus:border-primary-bright peer-checked:border-primary-bright peer-checked:ring-1 peer-checked:ring-offset-0 peer-checked:ring-primary-bright peer-checked:shadow-none peer-checked:text-primary-bold peer-checked:[&>svg]:text-primary-bold  lg:hover:border-primary-bright focus:outline-none rounded-[4px] border-gray-50 border select-none p-3":
                checkboxStyle === "bordered",
              " ring-primary-bright border-primary-bright border":
                onFocus && checkboxStyle === "bordered",
              "py-1.5 select-none w-fit justify-start flex-row-reverse":
                checkboxStyle === "regular",
              "cursor-auto": disabled,
            },
            className
          )}
        >
          <div>{label}</div>

          <input
            id={id}
            ref={ref}
            type="checkbox"
            disabled={disabled}
            onFocus={() => setOnFocus(true)}
            onBlur={() => setOnFocus(false)}
            className={classNames(
              "h-4 w-4 mt-[1px] mr-2 border-2 cursor-pointer border-text-light-200 text-primary-bold rounded-sm focus:outline-none focus:border-primary-bold focus:ring-0 focus:ring-offset-0",
              {
                "opacity-24 disabled:bg-background-dark-100 disabled:border-other-light-100 disabled:cursor-auto":
                  disabled,
              }
            )}
            {...props}
          />
        </label>
        {feedback && <Feedback status={status}>{feedback}</Feedback>}
      </>
    );
  }
);

export default _Checkbox;
