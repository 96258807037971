import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "next-i18next";
import { BaseInputProps } from "@common/types/FormInputProps";
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldValues,
  Path,
} from "react-hook-form";
import useFeatureFlags from "@modules/feature-flags/hooks/useFeatureFlags";
import { FeatureFlags } from "@modules/feature-flags/types/FeatureFlag";
import useGetLocations from "@modules/locations/hooks/useGetLocations";
import { Address } from "@common/types/Address";
import Location from "@modules/locations/types/Location";
import { getDealerFromAddress } from "@common/helpers/distanceMatrixHelper";
import Listbox, { Props as ListBoxProps } from "./Listbox";
import AddressAutoComplete from "./AddressAutoComplete";

export type LocationWithNameOverride = Location & { nameOverride?: string };

type WithControllerProps<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T>;
  rules?: Parameters<Control<T>["register"]>[1];
};

type WithoutControllerProps = {
  selectedLocation: LocationWithNameOverride | undefined;
  setSelectedLocation: Dispatch<SetStateAction<Location | undefined>>;
};

type Props<T extends FieldValues> = (
  | WithControllerProps<T>
  | WithoutControllerProps
) & {
  dealerDropdownLabel?: string;
  addressAutoCompleteLabel?: string;
  menuPosition?: ListBoxProps<unknown>["menuPosition"];
  buttonRef?: ControllerRenderProps["ref"];
  nameOverride?: (address: Address) => string;
  onSetLocation?: (location?: Location) => void;
} & Omit<BaseInputProps, "onChange" | "label" | "name">;

// eslint-disable-next-line no-underscore-dangle
const _LocationPicker = <T extends FieldValues>({
  dealerDropdownLabel,
  addressAutoCompleteLabel,
  fieldError,
  menuPosition = "Down",
  buttonRef,
  nameOverride,
  onSetLocation,
  ...props
}: Props<T>) => {
  const { t } = useTranslation(["common"]);
  const { isEnabled } = useFeatureFlags();
  const { locations } = useGetLocations();
  const [autoCompleteValue, setAutoCompleteValue] = useState<
    string | undefined
  >();
  const isHideRightRideLocationsEnabled = isEnabled(
    FeatureFlags.HideRightRideLocations
  );

  const getLocationFromAddress = (
    address: Address
  ): LocationWithNameOverride => {
    return {
      ...getDealerFromAddress(address, locations),
      nameOverride: nameOverride
        ? nameOverride(address)
        : `${address.city}, ${address.provinceAbbr}`,
    } as LocationWithNameOverride;
  };

  const renderInputs = (field?: ControllerRenderProps<T>) => {
    const { selectedLocation, setSelectedLocation } =
      "selectedLocation" in props
        ? props
        : { selectedLocation: undefined, setSelectedLocation: undefined };
    const sanitizedProps =
      "selectedLocation" in props
        ? (({
            selectedLocation: selectedLocationToRemove,
            setSelectedLocation: setSelectedLocationToRemove,
            ...rest
          }) => rest)(props)
        : props;
    return isHideRightRideLocationsEnabled ? (
      <AddressAutoComplete
        fieldError={fieldError}
        label={
          addressAutoCompleteLabel || t("common:enter_postal_code_or_city")
        }
        onAddressSelect={(address) => {
          const dealer = getLocationFromAddress(address);
          field?.onChange(dealer);
          if (setSelectedLocation && dealer) setSelectedLocation(dealer);
          if (onSetLocation) onSetLocation(dealer);
          setAutoCompleteValue(
            nameOverride?.(address) ||
              `${address.city}, ${address.provinceAbbr}`
          );
        }}
        onAddressClear={() => {
          field?.onChange(undefined);
          if (setSelectedLocation) setSelectedLocation(undefined);
          if (onSetLocation) onSetLocation(undefined);
          setAutoCompleteValue("");
        }}
        lookupType="(regions)"
        onChange={(e) => {
          field?.onChange(e);
          setAutoCompleteValue(e.target.value);
        }}
        showShortAddress
        value={
          (field?.value as unknown as LocationWithNameOverride)?.nameOverride ||
          field?.value ||
          selectedLocation?.nameOverride ||
          autoCompleteValue ||
          ""
        }
        ref={field?.ref || buttonRef}
        updateUserLocationOnSelect
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...sanitizedProps}
      />
    ) : (
      <Listbox
        items={locations}
        label={dealerDropdownLabel || t("common:closest_rightride_location")}
        keyOption={(item) => item.id}
        displayOption={(item) => item?.name || ""}
        displayButton={(item) => item?.name || t("common:select_location")}
        valueOption={(item) => item}
        status={fieldError ? "error" : "default"}
        feedback={
          fieldError?.message ||
          (fieldError?.type === "required" && t("common:location_required"))
        }
        onChange={(e) => {
          if (setSelectedLocation) setSelectedLocation(e);
          if (onSetLocation) onSetLocation(e);
          field?.onChange(e);
        }}
        defaultButtonClassName="text-slate-500"
        menuPosition={menuPosition}
        buttonRef={field?.ref || buttonRef}
        selectedItem={field?.value || selectedLocation}
        {...sanitizedProps}
      />
    );
  };

  return "control" in props ? (
    <Controller
      control={props.control}
      rules={props.rules}
      name={props.name}
      render={({ field }) => {
        return renderInputs(field);
      }}
    />
  ) : (
    renderInputs()
  );
};

export default _LocationPicker;
