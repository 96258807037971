import { BaseInputProps } from "@common/types/FormInputProps";
import classNames from "classnames";

type Props = Pick<
  BaseInputProps,
  "leftIcon" | "rightIcon" | "border" | "placeholderSize" | "status"
>;

export default function getInputStyles({
  leftIcon,
  rightIcon,
  border = "normal",
  placeholderSize = "medium",
  status = "default",
}: Props): string {
  const statusColor: Record<string, string> = {
    default:
      "border-other-light-100 focus:border-primary-bold focus:shadow-primary-bold/8",
    error: "border-error focus:border-error focus:shadow-error/8",
  };
  return classNames(
    "body-3 form-input font-medium kijiji:font-normal autocan:font-normal bg-white rounded-lg w-full focus:outline-none focus:ring-0 focus:shadow-input placeholder-gray-500",
    "disabled:bg-background-light-300 disabled:border-other-light-100",
    {
      "pl-8": leftIcon,
    },
    {
      "pr-8": rightIcon,
    },
    {
      border: border === "normal",
    },
    {
      "border-2 focus:border-2": border === "thick",
    },
    {
      "placeholder:text-sm": placeholderSize === "small",
    },
    statusColor[status] || statusColor.default
  );
}
