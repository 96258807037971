import { TFunction, useTranslation } from "next-i18next";
import { useState } from "react";
import WhereIsMyVinImage from "@public/images/where-is-my-vin.png";
import classNames from "classnames";
import { TranslationKeys } from "src/@types/i18next";
import {
  HoverCard,
  HoverCardContent,
  HoverCardPortal,
  HoverCardTrigger,
} from "@common/components/HoverCard";
import LocalImage from "../../LocalImage";
import Modal from "../../Modal";
import Button from "../../Button";

const whereIsMyVinGraphic = (
  t: TFunction,
  scaleToToolTip?: boolean,
  src?: string
) => {
  return (
    <div className="flex flex-col items-center py-4 mx-auto sm:w-[456px]">
      <LocalImage
        src={src ?? WhereIsMyVinImage}
        alt={t("where_is_my_vin_graphic", { ns: "common" })}
        width="341"
        priority
        className="mb-6"
      />

      <div
        className={classNames(
          "w-full text-start text-primary-deep kijiji:text-gray-500",
          {
            "px-6": scaleToToolTip,
            "px-1 sm:px-2": !scaleToToolTip,
          }
        )}
      >
        <h1 className="body-1 font-semibold pb-4">
          {t("you_can_find_your_vin", { ns: "common" })}
        </h1>

        <div className="flex flex-col sm:flex-row gap-4 justify-between px-1">
          <div>
            <p className="body-3 font-medium text-base pb-1">
              {t("within_the_vehicle", { ns: "common" })}:
            </p>
            <ul className="list-disc list-inside body-4 text-base font-normal">
              <li>{t("drivers_side_dashboard", { ns: "common" })}</li>
              <li>{t("inside_drivers_door", { ns: "common" })}</li>
            </ul>
          </div>
          <div>
            <p className="body-3 font-medium text-base pb-1">
              {t("in_documents", { ns: "common" })}:
            </p>
            <ul className="list-disc list-inside body-4 text-base font-normal">
              <li>{t("ownership_document", { ns: "common" })}</li>
              <li>{t("insurance_policy", { ns: "common" })}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

type Props = {
  themeClassNames?: string;
  labelKey?: TranslationKeys["common"];
  src?: string;
};

const WhereIsMyVinGraphic = ({
  themeClassNames,
  labelKey = "common:where_is_my_vin",
  src,
}: Props) => {
  const { t } = useTranslation(["common"]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div id="whereIsMyVin" className="focus:outline-non inline-block">
        <div className="justify-start flex text-primary-bold font-medium kijiji:font-normal autocan:font-normal hover:cursor-pointer">
          <div
            onClick={() => setIsModalOpen(true)}
            onKeyDown={() => setIsModalOpen(true)}
            role="button"
            tabIndex={0}
            className="md:hidden"
          >
            <p className="underline">{t(labelKey)}</p>
          </div>
          <div className="hidden md:block">
            <HoverCard openDelay={300}>
              <HoverCardTrigger asChild>
                <p className="underline">{t(labelKey)}</p>
              </HoverCardTrigger>
              <HoverCardPortal>
                <HoverCardContent
                  className="flex flex-col rounded-lg w-full ml-12 bg-white z-60"
                  align="center"
                  avoidCollisions
                >
                  {whereIsMyVinGraphic(t, true, src)}
                </HoverCardContent>
              </HoverCardPortal>
            </HoverCard>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        title={t("common:where_is_my_vin")}
        alignment="center"
        onClose={() => setIsModalOpen(false)}
        themeClassNames={themeClassNames}
      >
        {whereIsMyVinGraphic(t, false, src)}
        <div className="flex flex-col w-[30%] mx-auto pt-6">
          <Button
            spacing="tight-hug"
            size="small"
            onClick={() => setIsModalOpen(false)}
          >
            {t("common:got_it")}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default WhereIsMyVinGraphic;
