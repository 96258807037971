import Link from "next/link";
import LocalImage from "@common/components/LocalImage";
import RightRideLogo from "@public/images/logo-rightride-dark.svg";
import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import TextLink from "@common/components/TextLink";
import {
  AppFacebookIcon,
  AppInstagramIcon,
  AppTikTokIcon,
  AppYoutubeIcon,
  EmailIcon,
} from "@common/components/icons";
import AMVICLogo from "@public/images/amvic-logo.png";
import OMVICLogo from "@public/images/omvic-logo.png";

interface Menu {
  label: string;
  href: string;
}

const currentYear = new Date().getFullYear();

const Footer = () => {
  const { t } = useTranslation(["common"]);
  const footerMenu: Menu[] = useMemo(
    () => [
      {
        label: t("common:shop"),
        href: "/cars",
      },
      {
        label: t("common:careers"),
        href: "/about/careers",
      },
      {
        label: t("common:financing"),
        href: "/financing",
      },
      {
        label: t("common:credit_check"),
        href: "/free-credit-check",
      },
      {
        label: t("common:locations"),
        href: "/locations",
      },
      {
        label: t("common:trade_in"),
        href: "/trade",
      },
      {
        label: t("common:blog"),
        href: "/blog",
      },
      {
        label: t("common:how_it_works"),
        href: "/how-it-works",
      },
      {
        label: t("common:about_us"),
        href: "/about",
      },
      {
        label: t("common:current_offers"),
        href: "/current-offers",
      },
      {
        label: t("common:help"),
        href: "/about#contact-us",
      },
    ],
    [t]
  );

  const nav = (
    <nav className="grid grid-cols-2 gap-y-3 gap-x-14 md:gap-x-32 lg:gap-x-24">
      {footerMenu.map((m) => {
        return (
          <Link
            key={m.label}
            href={m.href}
            passHref
            className="text-white hover:text-white/50 whitespace-nowrap"
          >
            {m.label}
          </Link>
        );
      })}
    </nav>
  );

  return (
    <div className="bg-primary-deep px-4 lg:px-20 py-8 lg:py-12">
      <div className="flex flex-col md:flex-row justify-between gap-8 md:gap-16 -mb-5 md:-mb-0">
        <div className="md:w-1/2 lg:w-1/3">
          <Link href="/">
            <LocalImage
              width="173"
              height="39"
              className="hover:cursor-pointer"
              src={RightRideLogo}
              alt="RightRide Logo"
              priority
            />
          </Link>
          <ul className="flex gap-6 text-gray-200 pt-6">
            <li>
              <Link
                href="https://www.facebook.com/driverightride"
                target="_blank"
                aria-label="Navigate to RightRide Facebook Page"
              >
                <AppFacebookIcon className="w-6 h-6" />
              </Link>
            </li>
            <li>
              <Link
                href="https://www.instagram.com/driverightride"
                target="_blank"
                aria-label="Navigate to RightRide Instagram"
              >
                <AppInstagramIcon className="w-6 h-6" />
              </Link>
            </li>
            <li>
              <Link
                href="https://www.tiktok.com/@driverightride"
                target="_blank"
                aria-label="Navigate to RightRide TikTok"
              >
                <AppTikTokIcon className="w-5 h-6" />
              </Link>
            </li>
            <li>
              <Link
                href="https://www.youtube.com/@hellorightride823"
                target="_blank"
                aria-label="Navigate to RightRide YouTube"
              >
                <AppYoutubeIcon className="w-6 h-6" />
              </Link>
            </li>
            <li>
              <Link
                href="mailto:hello@rightride.ca"
                target="_blank"
                aria-label="Send an email to RightRide"
              >
                <EmailIcon className="w-5 h-6" />
              </Link>
            </li>
          </ul>

          <div className="hidden md:block lg:hidden pt-8">{nav}</div>
        </div>

        <div className="block md:hidden lg:block lg:w-1/3">{nav}</div>

        <div className="md:w-1/2 lg:w-1/3 md:mt-12 lg:mt-0" />
      </div>

      <div className="w-fit">
        <div className="flex flex-row flex-wrap items-center gap-x-8 py-8 lg:py-0 lg:pt-2 lg:pb-4">
          <LocalImage
            src={AMVICLogo}
            alt="AMVIC Logo"
            width="92"
            height="92"
            className="object-cover h-full"
          />
          <LocalImage
            src={OMVICLogo}
            alt="OMVIC Logo"
            width="92"
            height="92"
            className="object-cover h-full"
          />
        </div>
        <ul className="flex flex-wrap lg:flex-nowrap gap-y-2 gap-x-4 md:gap-x-8 list-none text-gray-200 caption-4">
          <li className="basis-full md:basis-auto">
            © {currentYear} RightRide
          </li>
          <li>
            <TextLink className="text-gray-200" href="/privacy-policy">
              <a>{t("common:privacy_policy")}</a>
            </TextLink>
          </li>
          <li>
            <TextLink className="text-gray-200" href="/terms-conditions">
              <a>{t("common:terms_and_conditions")}</a>
            </TextLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
