import { gtmPushData } from "@common/helpers/gtm";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@common/components/icons";
import classNames from "classnames";

type FilterDisclosureProps = {
  headerText: string;
  sideHeaderText?: string;
  children: JSX.Element;
  active?: boolean;
  defaultOpen?: boolean;
  childPadding?: "xs" | "sm" | "md";
  onOpen?: () => void;
  onClose?: () => void;
};

const FilterDisclosure = ({
  headerText,
  sideHeaderText = "",
  children,
  active,
  defaultOpen = false,
  childPadding = "md",
  onOpen,
  onClose,
}: FilterDisclosureProps) => {
  const disclosureButtonClick = (open: boolean) => {
    gtmPushData({
      event: "Filter",
      element: "Dropdown",
      descriptor: headerText,
    });

    if (!open && onOpen) onOpen();
    if (open && onClose) onClose();
  };
  return (
    <div className="w-full relative">
      <div className="mx-auto w-full bg-white min-h-full border-b border-gray-50">
        {/* Headless-UI Disclosure does not support opening programmatically yet. So, we use key prop to force re-render. Since whenever key changes, the new defaultOpen is taken into account */}
        <Disclosure defaultOpen={defaultOpen} key={`${defaultOpen}`}>
          {({ open }) => {
            return (
              <>
                <Disclosure.Button
                  onClick={() => disclosureButtonClick(open)}
                  className="flex w-full justify-between rounded-lg bg-white text-left px-6 focus:outline-none focus-visible:shadow-elevation-03 py-4"
                >
                  <div className="w-auto focus:outline-none">
                    <span
                      className={`${
                        active ? "visible" : "invisible"
                      } absolute xs:top-6 left-3 h-2 w-2 rounded-full inline-block bg-secondary-salmon `}
                    />
                    <h5
                      className={classNames(
                        "inline-block body-3 text-primary-deep",
                        {
                          "font-semibold": open,
                        }
                      )}
                    >
                      {headerText}
                    </h5>
                  </div>

                  <div className="inline-flex items-center justify-center">
                    <span className="caption-3 text-text-light-200 uppercase tracking-[1px] pr-2">
                      {sideHeaderText}
                    </span>
                    <ChevronUpIcon
                      className={`${
                        open
                          ? "text-primary-bold"
                          : "rotate-180 transform text-primary-deep"
                      } h-6 w-6 `}
                    />
                  </div>
                </Disclosure.Button>
                <Disclosure.Panel
                  className={classNames("pb-6 focus:outline-none", {
                    "px-3 pt-2": childPadding === "xs",
                    "px-4 pt-2": childPadding === "sm",
                    "px-6 pt-2": childPadding === "md",
                  })}
                >
                  {children}
                </Disclosure.Panel>
              </>
            );
          }}
        </Disclosure>
      </div>
    </div>
  );
};

export default FilterDisclosure;
