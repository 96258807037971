const tradeInQueryKeys = {
  all: ["tradeIn"] as const,
  details: (source: string) =>
    [...tradeInQueryKeys.all, "details", source] as const,
  options: (source: string) =>
    [...tradeInQueryKeys.all, "options", source] as const,
  features: (source: string) =>
    [...tradeInQueryKeys.all, "features", source] as const,
  token: (source: string) =>
    [...tradeInQueryKeys.all, "token", source] as const,
  questions: (source: string, tradeInId: string) =>
    [...tradeInQueryKeys.all, "questions", source, tradeInId] as const,
  appraisalVehicleData: (source: string) =>
    [...tradeInQueryKeys.all, "appraisalVehicleData", source] as const,
  appraisalCustomerData: (tradeInId: string) =>
    [...tradeInQueryKeys.all, "appraisalCustomerData", tradeInId] as const,
  reviseOfferReasons: (tradeInId: string) =>
    [...tradeInQueryKeys.all, "reviseOfferReasons", tradeInId] as const,
  appraisalVehicleImages: (tradeInId: string, vehicleImageIds: string[]) =>
    [
      ...tradeInQueryKeys.all,
      "appraisalVehicleImage",
      tradeInId,
      vehicleImageIds,
    ] as const,
  appraisalPreviewScannedImageIds: (tradeInTokenId: string) =>
    [
      ...tradeInQueryKeys.all,
      "appraisalPreviewScannedImageIds",
      tradeInTokenId,
    ] as const,
};

export default tradeInQueryKeys;
