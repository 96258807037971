import classNames from "classnames";

export type ArrowPlacement =
  | "top-left"
  | "top"
  | "top-right"
  | "bottom-left"
  | "bottom"
  | "bottom-right"
  | "bottom-large";

type Props = {
  children: React.ReactNode;
  content: React.ReactNode;
  arrowPlacement?: ArrowPlacement;
  show?: boolean;
  toolTipStyle?: "primary" | "light";
  displayOn?: "hover" | "click";
  onChildrenClick?(): void;
  toolTipClassName?: string;
  showOnHover?: boolean;
  fillAvailableSpace?: boolean;
  showArrow?: boolean;
};

const Tooltip = ({
  children,
  content,
  arrowPlacement = "bottom",
  show = false,
  toolTipStyle = "primary",
  displayOn = "hover",
  onChildrenClick,
  toolTipClassName = "",
  showOnHover = true,
  fillAvailableSpace,
  showArrow = true,
}: Props) => {
  let arrowContainerClasses;
  let arrowClasses;
  let parentClasses;

  switch (arrowPlacement) {
    case "top-left":
      arrowContainerClasses =
        "flex-col-reverse items-start left-[calc(50%-0.5rem)] left-1/2 top-[calc(100%+0.25rem)]";
      arrowClasses = "ml-2 -mb-1";
      parentClasses = "items-start";
      break;
    case "top":
      arrowContainerClasses =
        "flex-col-reverse items-center top-[calc(100%+0.25rem)]";
      arrowClasses = "-mb-1";
      parentClasses = "items-center";
      break;
    case "top-right":
      arrowContainerClasses =
        "flex-col-reverse items-end right-[calc(50%-0.5rem)] top-[calc(100%+0.25rem)]";
      arrowClasses = "mr-2 -mb-1";
      parentClasses = "items-end";
      break;
    case "bottom-left":
      arrowContainerClasses =
        "flex-col items-start left-[calc(50%-0.5rem)] bottom-[calc(100%+0.25rem)]";
      arrowClasses = "ml-2 -mt-1";
      parentClasses = "items-start";
      break;
    case "bottom":
      arrowContainerClasses =
        "flex-col items-center bottom-[calc(100%+0.25rem)]";
      arrowClasses = "-mt-1";
      parentClasses = "items-center";
      break;
    case "bottom-right":
      arrowContainerClasses =
        "flex-col items-end right-[calc(50%-0.5rem)] bottom-[calc(100%+0.25rem)]";
      arrowClasses = "mr-2 -mt-1";
      parentClasses = "items-end";
      break;
    case "bottom-large":
      arrowContainerClasses =
        "flex-col items-center lg:left-[calc(50%-10.0rem)] bottom-[calc(100%+0.25rem)]";
      arrowClasses = "-mt-1 mr-64";
      parentClasses = "items-center";
      break;
    default:
      throw Error(`Arrow placement "${arrowPlacement}" not implemented`);
  }

  const wrapChildrenWithLink = (childs: React.ReactNode) => {
    if (displayOn !== "click" || onChildrenClick === undefined) {
      return children;
    }

    return (
      <a
        href="#"
        onClick={(e) => {
          onChildrenClick();
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {childs}
      </a>
    );
  };

  return (
    <div
      className={classNames(
        "relative inline-flex items-center flex-col group",
        {
          parentClasses,
          "w-full h-full": fillAvailableSpace,
        }
      )}
    >
      {wrapChildrenWithLink(children)}
      <div
        className={classNames(
          "absolute max-w-xs w-max",
          arrowContainerClasses,
          toolTipClassName,
          {
            flex: show,
            hidden: (displayOn === "hover" || displayOn === "click") && !show,
            "group-hover:flex": displayOn === "hover" && !show && showOnHover,
          }
        )}
      >
        <span
          className={classNames(
            "relative z-40 px-3 caption-1 text-primary-bold shadow-elevation-03",
            {
              "bg-primary-light rounded": toolTipStyle === "primary",
              "bg-background-light-100 rounded-lg": toolTipStyle === "light",
              "py-2": showArrow,
              "py-0": !showArrow,
            }
          )}
        >
          {content}
        </span>
        {showArrow && (
          <div
            className={classNames(
              "w-2 h-2 rotate-45 z-40",
              {
                "bg-primary-light": toolTipStyle === "primary",
                "bg-background-light-100": toolTipStyle === "light",
              },
              arrowClasses
            )}
          />
        )}
      </div>
    </div>
  );
};

export default Tooltip;
