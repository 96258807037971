import GeneralForm from "@modules/cms/components/GeneralForm";
import ReferAFriendForm from "@modules/cms/components/ReferAFriendForm";
import PickYourPerkForm from "@modules/cms/components/PickAPerkForm";
import GiveawayForm from "@modules/cms/components/GiveawayForm";
import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import GoogleMapsWrapper from "@modules/location/helpers/GoogleMapsWrapper";
import WarrantyCoverage from "@modules/purchase/components/aftermarket/WarrantyCoverage";
import WarrantyGraph from "@modules/purchase/components/aftermarket/WarrantyGraph";
import WarrantySelectCoverage from "@modules/purchase/components/aftermarket/WarrantySelectCoverage";
import FinancingCalculatorWrapper from "@modules/purchase/components/aftermarket/FinancingCalculatorWrapper";
import FinancingHeading from "@modules/purchase/components/aftermarket/FinancingHeading";
import DynamicHeroHeading from "@modules/cms/components/DynamicHeroHeading";
import WarrantyVehicleComponent from "@modules/purchase/components/aftermarket/WarrantyVehicleComponent";
import ConsumerSellerHeading from "@modules/purchase/components/consumer/seller/ConsumerSellerHeading";
import StripeProvider from "@common/components/stripe/StripeProvider";
import InstantCashOfferHeading from "@modules/purchase/components/offer/InstantCashOfferHeading";
import FinancingPreQualification from "@modules/purchase/components/consumer/FinancingPreQualification";
import { gtmPushData } from "@common/helpers/gtm";
import { ExperienceResult } from "@modules/core/convert/hooks/useConvertExperience";
import { AftermarketDiscountConvertData } from "@modules/purchase/types/AftermarketDiscountConvertData";
import WarrantySegmentationSection from "@modules/purchase/components/aftermarket/WarrantySegmentationSection";
import { CmsLayout } from "../types/CmsLayout";
import { FeaturedInventoryLayout } from "../types/FeaturedInventoryLayout";
import { FullWidthCTABannerLayout } from "../types/FullWidthCTABannerLayout";
import { FormSelectorLayout } from "../types/FormSelectorLayout";
import {
  GalleryAndContentCarouselLayout,
  GalleryAndContentLayout,
} from "../types/GalleryAndContentLayout";
import { HeroHeadingLayout } from "../types/HeroHeadingLayout";
import { MultiCardSectionLayout } from "../types/MultiCardSectionLayout";
import { ReviewsCarouselLayout } from "../types/ReviewsCarouselLayout";
import { TeamListLayout } from "../types/TeamListLayout";
import FeaturedInventory from "./FeaturedInventory";
import FullWidthCTABanner from "./FullWidthCTABanner";
import GalleryAndContent from "./GalleryAndContent";
import HeroHeading from "./HeroHeading";
import MultiCardSection from "./MultiCardSection";
import ReviewsCarousel from "./ReviewsCarousel";
import LocationsContactForm from "./LocationsContactForm";
import TeamList from "./TeamList";
import { FaqLayout } from "../types/FaqLayout";
import Faq from "./Faq";
import IFrame from "./IFrame";
import { IFrameLayout } from "../types/IFrameLayout";
import ContentHeader from "./ContentHeader";
import { ContentHeaderLayout } from "../types/ContentHeaderLayout";
import { ContentAreaLayout } from "../types/ContentAreaLayout";
import ContentArea from "./ContentArea";
import { EmbeddedVideoPlayerLayout } from "../types/EmbeddedVideoPlayerLayout";
import EmbeddedVideoPlayer from "./EmbeddedVideoPlayer";
import { MultiStepLayout } from "../types/MultiStepLayout";
import MultiStepSection from "./MultiStepSection";
import { ButtonGroupsContentFields } from "../types/ButtonGroupsLayout";
import ButtonGroupsSection from "./ButtonGroupsSection";
import { ContentBlockFlexLayout } from "../types/ContentBlockFlexLayout";
import { WarrantyCoverageLayout } from "../types/WarrantyCoverageLayout";
import { WarrantyGraphLayout } from "../types/WarrantyGraphLayout";
import { FinancingHeadingLayout } from "../types/FinancingHeadingLayout";
import { FinancingCalculatorLayout } from "../types/FinancingCalculatorLayout";
import { DynamicHeroHeadingLayout } from "../types/DynamicHeroHeadingLayout";
import { WarrantyVehicleComponentLayout } from "../types/WarrantyVehicleComponentLayout";
import { ConsumerSellerHeadingLayout } from "../types/ConsumerSellerHeadingLayout";
import { WarrantySelectCoverageLayout } from "../types/WarrantySelectCoverageLayout";
import { InstantCashOfferHeadingLayout } from "../types/InstantCashOfferHeadingLayout";
import GetBackgroundStyle from "../helpers/getBackgroundStyle";
import { FeatureBannerLayout } from "../types/FeatureBannerLayout";
import FeatureBanner from "./FeatureBanner";
import { WarrantyQuoteFormLayout } from "../types/WarrantyQuoteFormLayout";
import {
  GravityFormInput,
  GravityFormLayout,
} from "../types/GravityFormLayout";
import GravityForm from "./GravityForm";
import GalleryAndContentCarousel from "./GalleryAndContentCarousel";
import { FinancingPreQualificationFormLayout } from "../types/FinancingPreQualificationFormLayout";

type Props = {
  layouts: CmsLayout[];
  dealerId?: string;
  locale?: string;
  convertResult?: ExperienceResult<AftermarketDiscountConvertData>;
};

const CmsLayouts = ({ layouts, dealerId, locale, convertResult }: Props) => {
  const htmlIdRef = useRef<HTMLDivElement | null>(null);
  const [urlHtmlId, setUrlHtmlId] = useState<string | null>(null);
  const router = useRouter();

  useEffect(() => {
    if (router.asPath.includes("#") && !urlHtmlId) {
      setUrlHtmlId(router.asPath ? router.asPath.split("#")[1] : null);
    }
    if (htmlIdRef.current && urlHtmlId) {
      htmlIdRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [router.asPath, urlHtmlId]);

  const getLayoutComponent = (layout: CmsLayout) => {
    switch (layout?.typeName) {
      case "Page_Default_Flex_HeroHeading":
      case "Post_Default_Flex_HeroHeading": {
        const heroHeadingProps = layout as unknown as HeroHeadingLayout;
        return (
          <div
            ref={heroHeadingProps.htmlId === urlHtmlId ? htmlIdRef : undefined}
          >
            <HeroHeading
              heroLabel={heroHeadingProps.heroLabel}
              heroSubLabel={heroHeadingProps.heroSubLabel}
              button={heroHeadingProps.button}
              heroImage={heroHeadingProps.heroImage}
              imagePosition={heroHeadingProps.imagePosition}
              background={heroHeadingProps.background}
              search={heroHeadingProps.search}
              htmlIdRef={htmlIdRef}
            />
          </div>
        );
      }
      case "Page_Default_Flex_GalleryAndContent":
      case "Post_Default_Flex_GalleryAndContent": {
        const galleryProps = layout as unknown as GalleryAndContentLayout;
        return (
          <div
            ref={
              galleryProps.galleryAndContentGroup.htmlId === urlHtmlId
                ? htmlIdRef
                : undefined
            }
          >
            <GalleryAndContent
              galleryAndContentGroup={galleryProps.galleryAndContentGroup}
              htmlIdRef={htmlIdRef}
            />
          </div>
        );
      }
      case "Page_Default_Flex_GalleryAndContentCarousel":
      case "Post_Default_Flex_GalleryAndContentCarousel": {
        const galleryProps =
          layout as unknown as GalleryAndContentCarouselLayout;

        return (
          <GalleryAndContentCarousel
            galleryAndContentRepeater={galleryProps?.galleryAndContentRepeater}
            urlHtmlId={urlHtmlId}
            htmlIdRef={htmlIdRef}
          />
        );
      }
      case "Page_Default_Flex_TeamList": {
        const teamProps = layout as unknown as TeamListLayout;
        return (
          <TeamList title={teamProps.title} employees={teamProps.employees} />
        );
      }
      case "Page_Default_Flex_FeaturedInventory":
      case "Post_Default_Flex_FeaturedInventory": {
        const featuredInventoryProps =
          layout as unknown as FeaturedInventoryLayout;
        return (
          <div
            ref={
              featuredInventoryProps.htmlId === urlHtmlId
                ? htmlIdRef
                : undefined
            }
          >
            <FeaturedInventory fields={featuredInventoryProps} />
          </div>
        );
      }
      case "Page_Default_Flex_MultiCardSection":
      case "Post_Default_Flex_MultiCardSection": {
        const multiCardSection = layout as unknown as MultiCardSectionLayout;
        return (
          <div
            ref={multiCardSection.htmlId === urlHtmlId ? htmlIdRef : undefined}
          >
            <MultiCardSection
              sectionTitle={multiCardSection.sectionTitle}
              cardLayout={multiCardSection.cardLayout}
              button={multiCardSection.button}
              background={multiCardSection.background}
              htmlIdRef={htmlIdRef}
            />
          </div>
        );
      }
      case "Page_Default_Flex_ReviewsCarousel":
      case "Post_Default_Flex_ReviewsCarousel": {
        const reviewsCarousel = layout as unknown as ReviewsCarouselLayout;
        return (
          <div
            ref={reviewsCarousel.htmlId === urlHtmlId ? htmlIdRef : undefined}
          >
            <ReviewsCarousel
              carouselTitle={reviewsCarousel.carouselTitle}
              carouselContentList={reviewsCarousel.carouselContentList}
              background={reviewsCarousel.background}
            />
          </div>
        );
      }
      case "Page_Default_Flex_FullWidthCtaBanner":
      case "Post_Default_Flex_FullWidthCtaBanner": {
        const ctaBanner = layout as unknown as FullWidthCTABannerLayout;

        if (ctaBanner) {
          return (
            <div ref={ctaBanner.htmlId === urlHtmlId ? htmlIdRef : undefined}>
              <FullWidthCTABanner
                ctaBannerList={ctaBanner.ctaBannerList}
                background={ctaBanner.background}
                htmlIdRef={htmlIdRef}
              />
            </div>
          );
        }
        return null;
      }
      case "Page_Default_Flex_FormSelector":
      case "Post_Default_Flex_FormSelector": {
        const formSelectorProps = layout as unknown as FormSelectorLayout;

        switch (formSelectorProps.form) {
          case "Locations Contact": {
            if (dealerId) {
              return (
                <div
                  ref={
                    formSelectorProps.formId === urlHtmlId
                      ? htmlIdRef
                      : undefined
                  }
                >
                  <GoogleMapsWrapper>
                    <LocationsContactForm
                      description={formSelectorProps.description}
                      title={formSelectorProps.title}
                      campaignTag={formSelectorProps.campaignTag}
                      dealerId={dealerId}
                      backgroundImageGroup={
                        formSelectorProps.backgroundImageGroup
                      }
                      background={formSelectorProps.background}
                    />
                  </GoogleMapsWrapper>
                </div>
              );
            }

            return null;
          }
          case "General": {
            return (
              <div
                ref={
                  formSelectorProps.formId === urlHtmlId ? htmlIdRef : undefined
                }
              >
                <GoogleMapsWrapper>
                  <GeneralForm
                    description={formSelectorProps.description}
                    title={formSelectorProps.title}
                    campaignTag={formSelectorProps.campaignTag}
                    dealerId={dealerId}
                    backgroundImageGroup={
                      formSelectorProps.backgroundImageGroup
                    }
                    locationDropdown={formSelectorProps.locationDropdown}
                    referredBy={formSelectorProps.referredBy}
                    messageBox={formSelectorProps.messageBox}
                    consentCheckbox={formSelectorProps.consentCheckbox}
                    background={formSelectorProps.background}
                  />
                </GoogleMapsWrapper>
              </div>
            );
          }
          case "Pick your Perk": {
            return (
              <div
                ref={
                  formSelectorProps.formId === urlHtmlId ? htmlIdRef : undefined
                }
              >
                <GoogleMapsWrapper>
                  <PickYourPerkForm
                    description={formSelectorProps.description}
                    title={formSelectorProps.title}
                    campaignTag={formSelectorProps.campaignTag}
                    dealerId={dealerId}
                    backgroundImageGroup={
                      formSelectorProps.backgroundImageGroup
                    }
                    background={formSelectorProps.background}
                  />
                </GoogleMapsWrapper>
              </div>
            );
          }
          case "Refer a Friend": {
            return (
              <div
                ref={
                  formSelectorProps.formId === urlHtmlId ? htmlIdRef : undefined
                }
              >
                <GoogleMapsWrapper>
                  <ReferAFriendForm
                    description={formSelectorProps.description}
                    title={formSelectorProps.title}
                    campaignTag={formSelectorProps.campaignTag}
                    dealerId={dealerId}
                    backgroundImageGroup={
                      formSelectorProps.backgroundImageGroup
                    }
                    background={formSelectorProps.background}
                  />
                </GoogleMapsWrapper>
              </div>
            );
          }
          case "Giveaway": {
            return (
              <div
                ref={
                  formSelectorProps.formId === urlHtmlId ? htmlIdRef : undefined
                }
              >
                <GoogleMapsWrapper>
                  <GiveawayForm
                    description={formSelectorProps.description}
                    title={formSelectorProps.title}
                    campaignTag={formSelectorProps.campaignTag}
                    dealerId={dealerId}
                    backgroundImageGroup={
                      formSelectorProps.backgroundImageGroup
                    }
                    locationDropdown={formSelectorProps.locationDropdown}
                    consentCheckbox={formSelectorProps.consentCheckbox}
                    background={formSelectorProps.background}
                  />
                </GoogleMapsWrapper>
              </div>
            );
          }
          default:
            return null;
        }
      }
      case "Page_Default_Flex_GravityForm":
      case "Post_Default_Flex_GravityForm": {
        const gravityFormProps = layout as unknown as GravityFormLayout;
        return gravityFormProps.form ? (
          <div
            ref={gravityFormProps.htmlId === urlHtmlId ? htmlIdRef : undefined}
          >
            <GravityForm
              form={gravityFormProps.form}
              formId={gravityFormProps.id}
              vinsolutionsId={gravityFormProps.vinsolutionsId}
              campaignTag={gravityFormProps.campaignTag}
              backgroundImageGroup={gravityFormProps.backgroundImageGroup}
              background={gravityFormProps.background}
              confirmationText={
                gravityFormProps.form.confirmations?.[0].message
              }
              submitButtonText={gravityFormProps.form.submitButton?.text}
              onSubmit={(formInput: GravityFormInput) => {
                if (dealerId) {
                  gtmPushData({
                    event: "Locations",
                    element: "Form",
                    location: dealerId,
                    descriptor: "Submit",
                    firstName: formInput.firstName,
                    lastName: formInput.lastName,
                    email: formInput.email,
                    phone: formInput.phoneNumber,
                  });
                } else if (router.asPath.startsWith("/about")) {
                  gtmPushData({
                    event: "About-Us",
                    descriptor: "Submit",
                    firstName: formInput.firstname,
                    lastName: formInput.lastname,
                    email: formInput.email,
                    phone: formInput.phoneNumber,
                  });
                }
              }}
            />
          </div>
        ) : null;
      }
      case "Page_Default_Flex_Faq":
      case "Post_Default_Flex_Faq": {
        const faqProps = layout as unknown as FaqLayout;
        const filteredFaqSections = faqProps.faqSections.filter(
          (x) => x.faqSectionHeading != null
        );
        return (
          <div ref={faqProps.htmlId === urlHtmlId ? htmlIdRef : undefined}>
            <Faq
              faqLabel={faqProps.faqLabel}
              faqSections={filteredFaqSections}
              background={faqProps.background}
            />
          </div>
        );
      }
      case "Page_Default_Flex_Iframe":
      case "Post_Default_Flex_Iframe": {
        const iframeProps = layout as unknown as IFrameLayout;
        return (
          <div ref={iframeProps.htmlId === urlHtmlId ? htmlIdRef : undefined}>
            <IFrame
              title={iframeProps.title}
              iframeSrc={iframeProps.iframeSrc}
              background={iframeProps.background}
            />
          </div>
        );
      }
      case "Page_Default_Flex_ContentBlockFlex":
      case "Post_Default_Flex_ContentBlockFlex": {
        const contentBlockProps = layout as unknown as ContentBlockFlexLayout;

        return (
          <div
            className="bg-white overflow-visible no-scrollbar relative bg-cover bg-center "
            style={GetBackgroundStyle(contentBlockProps.background)}
          >
            <div className="max-w-page mx-auto pt-10 pb-6 px-4 lg:px-5 lg:py-16">
              <CmsLayouts
                layouts={contentBlockProps.contentBlock as CmsLayout[]}
              />
            </div>
          </div>
        );
      }

      case "Page_Default_Flex_ContentBlockFlex_ContentBlock_ContentHeader":
      case "Post_Default_Flex_ContentBlockFlex_ContentBlock_ContentHeader": {
        const contentHeaderProps = layout as unknown as ContentHeaderLayout;
        return (
          <div
            ref={
              contentHeaderProps.htmlId === urlHtmlId ? htmlIdRef : undefined
            }
          >
            <ContentHeader
              header={contentHeaderProps.header}
              headerSize={contentHeaderProps.headerSize}
            />
          </div>
        );
      }

      case "Page_Default_Flex_ContentBlockFlex_ContentBlock_ContentArea":
      case "Post_Default_Flex_ContentBlockFlex_ContentBlock_ContentArea": {
        const contentAreaProps = layout as unknown as ContentAreaLayout;
        return (
          <div
            ref={contentAreaProps.htmlId === urlHtmlId ? htmlIdRef : undefined}
          >
            <ContentArea contentDetails={contentAreaProps.contentDetails} />
          </div>
        );
      }

      case "Page_Default_Flex_ContentBlockFlex_ContentBlock_GalleryAndContent":
      case "Post_Default_Flex_ContentBlockFlex_ContentBlock_GalleryAndContent": {
        const galleryProps = layout as unknown as GalleryAndContentLayout;
        return (
          <div
            ref={
              galleryProps.galleryAndContentGroup.htmlId === urlHtmlId
                ? htmlIdRef
                : undefined
            }
          >
            <GalleryAndContent
              galleryAndContentGroup={galleryProps.galleryAndContentGroup}
              isSubContent
            />
          </div>
        );
      }

      case "Page_Default_Flex_ContentBlockFlex_ContentBlock_EmbeddedVideo":
      case "Post_Default_Flex_ContentBlockFlex_ContentBlock_EmbeddedVideo": {
        const embeddedVideo = layout as unknown as EmbeddedVideoPlayerLayout;
        return (
          <div ref={embeddedVideo.htmlId === urlHtmlId ? htmlIdRef : undefined}>
            <EmbeddedVideoPlayer contentVideo={embeddedVideo.contentVideo} />
          </div>
        );
      }

      case "Post_Default_Flex_MultiStepSection":
      case "Page_Default_Flex_MultiStepSection": {
        const multiStep = layout as unknown as MultiStepLayout;
        return (
          <div ref={multiStep.htmlId === urlHtmlId ? htmlIdRef : undefined}>
            <MultiStepSection
              stepLayout={multiStep.stepLayout}
              sectionTitle={multiStep.sectionTitle}
              button={multiStep.button}
              background={multiStep.background}
              htmlIdRef={htmlIdRef}
            />
          </div>
        );
      }

      case "Page_Default_Flex_ButtonGroups":
      case "Post_Default_Flex_ButtonGroups": {
        const buttonGroupsContentProps =
          layout as unknown as ButtonGroupsContentFields;

        return (
          <div
            ref={
              buttonGroupsContentProps.htmlId === urlHtmlId
                ? htmlIdRef
                : undefined
            }
          >
            <ButtonGroupsSection
              locale={locale}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...buttonGroupsContentProps}
            />
          </div>
        );
      }

      case "Page_Default_Flex_FeatureBanner":
      case "Post_Default_Flex_FeatureBanner": {
        const featureBannerLayout = layout as unknown as FeatureBannerLayout;
        return <FeatureBanner layout={featureBannerLayout} />;
      }

      case "Page_Default_Flex_WarrantyCoverage":
      case "Post_Default_Flex_WarrantyCoverage": {
        const warrantyCoverageLayout =
          layout as unknown as WarrantyCoverageLayout;

        return <WarrantyCoverage layout={warrantyCoverageLayout} />;
      }

      case "Page_Default_Flex_WarrantyGraph":
      case "Post_Default_Flex_WarrantyGraph": {
        const warrantyGraphLayout = layout as unknown as WarrantyGraphLayout;

        return <WarrantyGraph layout={warrantyGraphLayout} />;
      }

      case "Page_Default_Flex_WarrantySelectCoverage":
      case "Post_Default_Flex_WarrantySelectCoverage": {
        const warrantySelectCoverageLayout =
          layout as unknown as WarrantySelectCoverageLayout;

        return (
          <StripeProvider pageIncludesPaymentForm={false}>
            <WarrantySelectCoverage
              layout={warrantySelectCoverageLayout}
              convertResult={convertResult}
            />
          </StripeProvider>
        );
      }

      case "Page_Default_Flex_WarrantyQuoteForm":
      case "Post_Default_Flex_WarrantyQuoteForm": {
        const warrantyQuoteFormLayout =
          layout as unknown as WarrantyQuoteFormLayout;

        return (
          <StripeProvider pageIncludesPaymentForm={false}>
            <WarrantySegmentationSection
              layout={warrantyQuoteFormLayout}
              convertResult={convertResult}
            />
          </StripeProvider>
        );
      }

      case "Page_Default_Flex_WarrantyVehicleComponent":
      case "Post_Default_Flex_WarrantyVehicleComponent": {
        const componentLayout =
          layout as unknown as WarrantyVehicleComponentLayout;

        return <WarrantyVehicleComponent layout={componentLayout} />;
      }

      case "Page_Default_Flex_FinancingHeading":
      case "Post_Default_Flex_FinancingHeading": {
        const financingHeaderLayout =
          layout as unknown as FinancingHeadingLayout;

        return <FinancingHeading layout={financingHeaderLayout} />;
      }

      case "Page_Default_Flex_FinancingPreQualificationForm":
      case "Post_Default_Flex_FinancingPreQualificationForm": {
        const financingHeaderLayout =
          layout as unknown as FinancingPreQualificationFormLayout;

        return <FinancingPreQualification layout={financingHeaderLayout} />;
      }

      case "Page_Default_Flex_FinancingCalculator":
      case "Post_Default_Flex_FinancingCalculator": {
        const financingCalculatorLayout =
          layout as unknown as FinancingCalculatorLayout;

        return (
          <FinancingCalculatorWrapper layout={financingCalculatorLayout} />
        );
      }

      case "Page_Default_Flex_InstantCashOfferHeading":
      case "Post_Default_Flex_InstantCashOfferHeading": {
        const icoHeadingLayout =
          layout as unknown as InstantCashOfferHeadingLayout;

        return <InstantCashOfferHeading layout={icoHeadingLayout} />;
      }

      case "Page_Default_Flex_DynamicHeroHeading":
      case "Post_Default_Flex_DynamicHeroHeading": {
        const vehicleCtaBannerLayout =
          layout as unknown as DynamicHeroHeadingLayout;

        return (
          <DynamicHeroHeading
            heading={vehicleCtaBannerLayout.heading}
            subHeading={vehicleCtaBannerLayout.subHeading}
            button={vehicleCtaBannerLayout.button}
            secondaryButton={vehicleCtaBannerLayout.secondaryButton}
            image={vehicleCtaBannerLayout.image}
            imagePosition={vehicleCtaBannerLayout.imagePosition}
            background={vehicleCtaBannerLayout.background}
          />
        );
      }

      case "Page_Default_Flex_SellerHeading":
      case "Post_Default_Flex_SellerHeading": {
        const sellerHeadingLayout =
          layout as unknown as ConsumerSellerHeadingLayout;

        return <ConsumerSellerHeading layout={sellerHeadingLayout} />;
      }

      default:
        return null;
    }
  };

  return (
    <>
      {layouts.map((layout, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`${layout?.typeName}-${i}`}>{getLayoutComponent(layout)}</div>
      ))}
    </>
  );
};

export default CmsLayouts;
