import { CheckCircleIcon, ChevronUpIcon } from "@common/components/icons";
import { ExtendedWarrantyRatesResponse } from "@modules/purchase/types/ExtendedWarrantyRates";
import { IAExtendedWarrantyPrograms } from "@modules/purchase/types/IAProgram";
import warrantyComponentsByProgram from "@modules/purchase/types/WarrantyComponent";
import { useTranslation, Trans } from "next-i18next";
import { Disclosure, Transition } from "@headlessui/react";
import classNames from "classnames";
import { EW_ADDITIONAL_VEHICLE_COMPONENTS } from "@common/constants";

type Props = {
  extendedWarrantyDefaultRates: ExtendedWarrantyRatesResponse;
};

const WarrantyComponent = ({ extendedWarrantyDefaultRates }: Props) => {
  const { t } = useTranslation(["common", "purchase"]);
  const iaProgram = IAExtendedWarrantyPrograms.find(
    (p) => p.id === extendedWarrantyDefaultRates?.productDetails?.programId
  );

  const distinctUsedCoverageTypeIds = extendedWarrantyDefaultRates?.success
    ? extendedWarrantyDefaultRates?.coverages.reduce<string[]>(
        (acc, current) => {
          if (!acc.some((coverage) => coverage === current.type)) {
            acc.push(current.type);
          }
          return acc;
        },
        []
      )
    : [];

  const usedCoverages =
    iaProgram?.coverageTypes.filter((ct) =>
      distinctUsedCoverageTypeIds.includes(ct.abbr)
    ) ?? [];

  const warrantyComponents =
    iaProgram != null && iaProgram.id in warrantyComponentsByProgram
      ? warrantyComponentsByProgram[iaProgram.id]
      : [];

  // filter out components that match 0 returned coverage types
  const filteredWarrantyComponents = warrantyComponents.filter((wc) =>
    wc.coverages.some((c) => distinctUsedCoverageTypeIds.includes(c))
  );

  const onlySuperiorWrap =
    usedCoverages.length === 1 && usedCoverages[0].abbr === "SW";

  const onlyAMA = usedCoverages.length === 1 && usedCoverages[0].abbr === "MA";

  return (
    <>
      <div className="flex items-center gap-1 sm:gap-4 py-2">
        <h5 className="flex-1 px-2 overflow-x-clip overflow-ellipsis text-sm sm:text-xl">
          {onlyAMA
            ? t("purchase:ew_coverage_component_heading_ama")
            : t("purchase:components")}
        </h5>

        {usedCoverages.map((coverage) => (
          <h5
            key={`coverage-type-${coverage.abbr}`}
            className="w-[18.7%] sm:w-[18%] text-sm sm:text-xl text-center overflow-ellipsis overflow-x-clip"
          >
            {usedCoverages.length === 1
              ? t("purchase:ew_program_covered")
              : t(coverage.i18nKey)}
          </h5>
        ))}
      </div>
      {filteredWarrantyComponents.map((component) => (
        <Disclosure
          key={component.id}
          defaultOpen={component.id === EW_ADDITIONAL_VEHICLE_COMPONENTS}
        >
          {({ open }) => (
            <div
              key={`${component.id}-content`}
              className={classNames(
                "flex flex-col items-start sm:text-base border-b-2 border-gray-50",
                {
                  "border-transparent": open,
                }
              )}
            >
              <Disclosure.Button
                className={classNames(
                  "flex w-full justify-between bg-white text-left py-3 focus:outline-none",
                  {
                    "rounded-b-none": open,
                    "bg-gray-50": open,
                    "hover:bg-gray-25": !open,
                  }
                )}
              >
                <div className="flex items-center gap-1 sm:gap-4 w-full">
                  <div className="flex flex-1 items-center pr-2 pl-1 sm:px-2 overflow-x-clip">
                    {component.icon && (
                      <div className="flex-none mr-2">{component.icon}</div>
                    )}

                    <div className="font-medium w-fit text-sm sm:text-base">
                      {t(component.name)}
                    </div>

                    <div className="flex-none pl-1">
                      <ChevronUpIcon
                        className={`${
                          open
                            ? "text-primary-bold"
                            : "rotate-180 transform text-primary-deep"
                        } h-6 w-6`}
                      />
                    </div>
                  </div>

                  {usedCoverages.map((coverage) => (
                    <div
                      key={`warranty-components-coverage-${component.id}-${coverage.abbr}`}
                      className="w-[18.7%] sm:w-[18%] text-center"
                    >
                      {component.coverages.includes(coverage.abbr) ? (
                        <CheckCircleIcon className="w-5 h-5 text-primary-bold inline" />
                      ) : (
                        <span className="text-gray-200">—</span>
                      )}
                    </div>
                  ))}
                </div>
              </Disclosure.Button>
              <Transition
                unmount={false}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
                className="w-full bg-gray-25"
              >
                <Disclosure.Panel unmount={false} className="p-4">
                  <div className="text-start text-sm sm:text-base">
                    {component.id === EW_ADDITIONAL_VEHICLE_COMPONENTS ? (
                      <div
                        id="additional-vehicle-components"
                        className="flex flex-col items-start gap-6 w-full px-2 font-medium text-gray-500"
                      >
                        {t("purchase:some_examples_additional_components")}
                        <span className="font-normal">
                          {t(component.details.i18nkey)}
                        </span>
                        {t("purchase:always_refer_to_contract_details")}
                      </div>
                    ) : (
                      <div>
                        <p>{t(component?.details?.i18nkey)}</p>

                        {/* Specific coverage perks section. (Only Superior Wrap at the moment) */}
                        {component?.details?.coverageSpecificComponents &&
                          !onlySuperiorWrap && (
                            <div
                              id="specific-coverage"
                              key={`${component.id}-specific-coverage`}
                              className="mt-6"
                            >
                              <div key={`${component.id}-details`}>
                                <p className="text-primary-bold font-bold">
                                  <Trans
                                    t={t}
                                    i18nKey="purchase:coverage_only_perks"
                                    values={{
                                      coverageName: `${t(
                                        component.details
                                          .coverageSpecificComponents
                                          .coverageType.i18nKey
                                      )}`,
                                    }}
                                  />
                                </p>
                                <ul className="list-disc list-inside">
                                  {t(
                                    component.details.coverageSpecificComponents
                                      .i18nkey
                                  )
                                    .split(",")
                                    .map((item) => (
                                      <li key={item}>{item}</li>
                                    ))}
                                </ul>
                              </div>
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                </Disclosure.Panel>
              </Transition>
            </div>
          )}
        </Disclosure>
      ))}
    </>
  );
};

export default WarrantyComponent;
