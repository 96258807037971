import { forwardRef } from "react";
import { useTranslation } from "next-i18next";
import getBaseInputProps from "@common/helpers/form/getBaseInputProps";
import getInputStyles from "@common/helpers/form/getInputStyles";
import customerValidation from "@common/helpers/validations/customerValidation";
import { BaseInputProps } from "@common/types/FormInputProps";
import { usePostalCodeMask } from "@common/hooks/useMask";
import BaseInput from "./BaseInput";

// eslint-disable-next-line no-underscore-dangle
const _PostalCode = forwardRef<HTMLInputElement, BaseInputProps>(
  (
    {
      type = "text",
      id = "postalCode",
      disabled,
      autoComplete = "postal-code",
      defaultValue,
      className,
      suppressHotjar = true,
      fieldError,
      onChange,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation("common");
    const { maskPipe: maskPostalCodePipe, unMaskPipe: unMaskPostalCodePipe } =
      usePostalCodeMask();
    const getFeedback = () => {
      if (!fieldError?.message) {
        if (fieldError?.type === "required") {
          return t("required");
        }
        if (fieldError?.type === "pattern") {
          return t("invalid_postal_code");
        }
        if (fieldError?.type === "matchingProvince") {
          return t("postal_code_and_province_mismatch");
        }
      }
      return fieldError?.message;
    };
    const baseInputProps = getBaseInputProps({
      type,
      disabled,
      label: props.label || t("postal_code"),
      status: fieldError ? "error" : "default",
      feedback: getFeedback(),
      defaultValue: maskPostalCodePipe(defaultValue ?? ""),
      ...props,
    });
    const inputClassName = getInputStyles({
      leftIcon: baseInputProps.leftIcon,
      rightIcon: baseInputProps.rightIcon,
      border: baseInputProps.border,
      placeholderSize: baseInputProps.placeholderSize,
      status: baseInputProps.status,
    });
    return (
      <BaseInput id={id} className={className} {...baseInputProps}>
        <input
          id={id}
          className={inputClassName}
          type={type}
          autoComplete={autoComplete}
          maxLength={customerValidation.postalCode().maxLength}
          defaultValue={baseInputProps.defaultValue}
          label={baseInputProps.label}
          disabled={disabled}
          ref={ref}
          data-hj-allow={(!suppressHotjar && type === "text") || undefined}
          onChange={(e) => {
            const unmasked = unMaskPostalCodePipe(e.target.value);
            const masked = maskPostalCodePipe(unmasked);
            e.target.value = masked;

            if (onChange) onChange(e);
          }}
          {...props}
        />
      </BaseInput>
    );
  }
);

export default _PostalCode;
