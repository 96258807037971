import Button from "@common/components/Button";
import Form from "@common/components/form";
import Listbox from "@common/components/form/Listbox";
import { useCallback, useEffect, useState } from "react";
import {
  Controller,
  FieldError,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import useCreateLeadMutation from "@modules/leads/hooks/useCreateLeadMutation";
import { Trans, useTranslation } from "next-i18next";
import TextLink from "@common/components/TextLink";
import Feedback from "@common/components/form/Feedback";
import useGetLocations from "@modules/locations/hooks/useGetLocations";
import useWindowDimensions from "@common/hooks/useWindowDimensions";
import { ArrowRightIcon } from "@components/icons";
import FormContentHeader from "@modules/cms/components/FormContentHeader";
import classNames from "classnames";
import cloudflareImagesLoader from "@common/helpers/imageLoaders/cloudflareImagesLoader";
import LoadingSpinner from "@common/components/LoadingSpinner";
import useUser from "@modules/user/hooks/useUser";
import {
  convertDealerLocationToMapLocation,
  findClosestLocation,
} from "@common/helpers/distanceMatrixHelper";
import customerValidation from "@common/helpers/validations/customerValidation";
import { LocationWithNameOverride } from "@common/components/form/LocationPicker";
import { BackgroundImageGroup } from "../types/BackgroundImageGroup";
import FormSelectorBackgroundImage from "./FormSelectorBackgroundImage";
import { sizeClass } from "../types/SizeClass";
import { DEFAULT_FORM_SELECTOR_BACKGROUND_WIDTH } from "../constants/BackgroundImages";
import FormSubmissionModal from "./FormSubmissionModal";
import { CmsBackgroundType } from "../types/CmsBackground";
import GetBackgroundStyle from "../helpers/getBackgroundStyle";

type Props = {
  title: string;
  description: string;
  campaignTag: string;
  dealerId: string | undefined;
  backgroundImageGroup?: BackgroundImageGroup;
  background: CmsBackgroundType;
};

type GeneralFormInput = {
  firstname: string;
  lastname: string;
  email: string;
  phoneNumber: string;
  chosenOffer: { id: string; name: string };
  preferredLocation: LocationWithNameOverride;
  consent: boolean;
};

const PickYourPerkForm = ({
  title,
  description,
  campaignTag,
  dealerId,
  backgroundImageGroup,
  background,
}: Props) => {
  const { t } = useTranslation(["common"]);
  const { locations } = useGetLocations();
  const { user } = useUser();
  const [chosenOffer, setChosenOffer] = useState(undefined);
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<GeneralFormInput>({
    mode: "onSubmit",
  });

  const setClosestLocationToCustomer = useCallback(() => {
    if (user && user.longitude && user.latitude) {
      const mapLocation = findClosestLocation(
        user.latitude,
        user.longitude,
        locations.map((l) => convertDealerLocationToMapLocation(l))
      );

      if (mapLocation) {
        const location = locations.find((l) => l.id === mapLocation.id);

        if (location) {
          setValue("preferredLocation", location);
        }
      }
    }
  }, [locations, setValue, user]);

  const resetForm = useCallback(() => {
    reset({
      firstname: "",
      lastname: "",
      email: "",
      phoneNumber: "",
      chosenOffer: undefined,
      preferredLocation: undefined,
      consent: false,
    });
    setChosenOffer(undefined);
  }, [reset]);

  useEffect(() => {
    const defaultLocation = locations.find((x) => x.id === dealerId);
    if (defaultLocation) {
      setValue("preferredLocation", defaultLocation);
    } else if (!defaultLocation && !getValues().preferredLocation) {
      setClosestLocationToCustomer();
    }
  }, [dealerId, locations, getValues, setClosestLocationToCustomer, setValue]);

  const offers = [
    { id: "65 Inch TV", name: t("common:choose_your_offer_form_65_inch_tv") },
    {
      id: "500 Cash Back",
      name: t("common:choose_your_offer_form_500_cash_back"),
    },
    {
      id: "500 Gas Card",
      name: t("common:choose_your_offer_form_500_gas_card"),
    },
  ];

  const createLeadMutation = useCreateLeadMutation();
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState<boolean>(false);

  const handleFormSubmit: SubmitHandler<GeneralFormInput> = (
    formInput: GeneralFormInput
  ) => {
    // preferredLocation is required, this shouldn't interfere with anything
    const { preferredLocation } = formInput;
    if (preferredLocation) {
      createLeadMutation.mutate(
        {
          firstName: formInput.firstname,
          lastName: formInput.lastname,
          email: formInput.email,
          chosenOffer: formInput.chosenOffer.name,
          originUrl: document.location.href,
          leadDescription: campaignTag,
          dealerId: preferredLocation?.id,
          userDataConsentGiven: true, // Form has a disclaimer, so we can assume consent is given.
        },
        {
          onSuccess: () => {
            resetForm();
            setIsSubmitModalOpen(true);
          },
        }
      );
    }
  };

  const [width, setWidth] = useState(0);
  const { width: w } = useWindowDimensions();

  useEffect(() => {
    setWidth(w);
  }, [w]);

  return (
    <div
      className="bg-white overflow-visible no-scrollbar relative bg-cover bg-center"
      style={GetBackgroundStyle(background)}
    >
      <LoadingSpinner isLoading={createLeadMutation.isPending} isOverlay />
      {isSubmitModalOpen && (
        <FormSubmissionModal
          isOpen={isSubmitModalOpen}
          setState={setIsSubmitModalOpen}
        />
      )}
      <div
        className={classNames({
          "bg-no-repeat": backgroundImageGroup?.image,
          "bg-[center_right_-6rem]":
            backgroundImageGroup?.imagePosition === "Right" &&
            width < sizeClass["2xl"] &&
            width >= sizeClass.xl,
          "bg-[center_right_2rem]":
            backgroundImageGroup?.imagePosition === "Right" &&
            width < sizeClass["3xl"] &&
            width >= sizeClass["2xl"],
          "bg-[center_right_8rem]":
            backgroundImageGroup?.imagePosition === "Right" &&
            width < sizeClass["4xl"] &&
            width >= sizeClass["3xl"],
          "bg-[center_right_20rem]":
            backgroundImageGroup?.imagePosition === "Right" &&
            width >= sizeClass["4xl"],

          "bg-[center_left_-6rem]":
            backgroundImageGroup?.imagePosition === "Left" &&
            width < sizeClass["2xl"] &&
            width >= sizeClass.xl,
          "bg-[center_left_2rem]":
            backgroundImageGroup?.imagePosition === "Left" &&
            width < sizeClass["3xl"] &&
            width >= sizeClass["2xl"],
          "bg-[center_left_8rem]":
            backgroundImageGroup?.imagePosition === "Left" &&
            width < sizeClass["4xl"] &&
            width >= sizeClass["3xl"],
          "bg-[center_left_20rem]":
            backgroundImageGroup?.imagePosition === "Left" &&
            width >= sizeClass["4xl"],
        })}
        style={
          width >= sizeClass.xl &&
          backgroundImageGroup?.image &&
          backgroundImageGroup.image.length > 0
            ? {
                backgroundImage: `url(${cloudflareImagesLoader({
                  src: backgroundImageGroup?.image[0].sourceUrl || "",
                  width: DEFAULT_FORM_SELECTOR_BACKGROUND_WIDTH,
                })})`,
              }
            : {}
        }
      >
        <div
          className={classNames(
            "relative max-w-page mx-auto lg:mx-0 xl:mx-auto flex items-center py-10 ",
            {
              "flex-col": backgroundImageGroup,
              "px-5 items-center lg:items-stretch":
                !backgroundImageGroup ||
                !backgroundImageGroup.image ||
                (backgroundImageGroup.image &&
                  backgroundImageGroup.image.length === 0),
              "lg:flex-row px-5 lg:pl-5 lg:pr-0 xl:px-5":
                backgroundImageGroup?.imagePosition === "Right" &&
                backgroundImageGroup.image &&
                backgroundImageGroup.image.length > 0,
              "lg:flex-row-reverse px-5 lg:pl-0 lg:pr-5 xl:px-5":
                backgroundImageGroup?.imagePosition === "Left" &&
                backgroundImageGroup.image &&
                backgroundImageGroup.image.length > 0,
            }
          )}
        >
          <div
            className={classNames("", {
              "flex-1 lg:basis-[60%] xl:basis-[50%]":
                backgroundImageGroup?.image,
            })}
          >
            <FormContentHeader title={title} description={description} />
            <form
              onSubmit={handleSubmit(handleFormSubmit)}
              className="my-5 flex flex-col gap-5 md:grid md:grid-cols-2"
            >
              <Form.FirstName
                fieldError={errors.firstname}
                {...register("firstname", customerValidation.firstName)}
              />
              <Form.LastName
                fieldError={errors.lastname}
                {...register("lastname", customerValidation.lastName)}
              />
              <div className="col-span-2">
                <Form.Email
                  fieldError={errors.email}
                  {...register("email", customerValidation.email)}
                />
              </div>
              <Controller
                control={control}
                rules={{
                  onChange: (e) => setChosenOffer(e.target.value),
                  required: {
                    value: true,
                    message: t("common:preferred_location_form_error_required"),
                  },
                }}
                name="chosenOffer"
                render={({ field: { onChange } }) => (
                  <Listbox
                    feedback={errors.chosenOffer?.message}
                    status={errors.chosenOffer ? "error" : "default"}
                    displayButton={(item) =>
                      item?.name || t("common:dropdown_placeholder")
                    }
                    displayOption={(i) => i?.name || ""}
                    valueOption={(i) => i}
                    keyOption={(i) => i.id}
                    label={t("common:choose_your_offer")}
                    items={offers}
                    selectedItem={chosenOffer}
                    onChange={onChange}
                  />
                )}
              />
              <Form.LocationPicker
                name="preferredLocation"
                fieldError={errors.preferredLocation as FieldError}
                dealerDropdownLabel={t("common:preferred_location")}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t("common:preferred_location_form_error_required"),
                  },
                }}
              />
              <Form.Checkbox
                label={t("common:general_form_agreement")}
                className="col-span-2"
                {...register("consent", { required: true })}
              />
              {errors.consent && (
                <Feedback status="error">
                  {t("common:form_error_required_consent")}
                </Feedback>
              )}
              <div className="flex flex-col gap-5 md:flex-row md:items-center md:col-span-2">
                <Button
                  isSubmit
                  buttonStyle="primary"
                  className="flex-shrink-0"
                  rightIcon={<ArrowRightIcon />}
                >
                  {t("common:submit")}
                </Button>
              </div>
              <div className="flex flex-col gap-5 md:flex-row md:items-center md:col-span-2">
                <p>{t("common:choose_your_offer_terms")}</p>
              </div>
              <div className="flex flex-col justify-start gap-5 md:flex-row md:items-center md:col-span-2">
                <Trans t={t} i18nKey="common:general_form_disclaimer">
                  <p className="italic text-xs">
                    <TextLink href="/privacy-policy">
                      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                      <a target="_blank" />
                    </TextLink>
                    <TextLink href="/terms-conditions">
                      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                      <a target="_blank" />
                    </TextLink>
                  </p>
                </Trans>
              </div>
            </form>
          </div>
          {backgroundImageGroup &&
            backgroundImageGroup.image &&
            backgroundImageGroup.image.length > 0 && (
              <FormSelectorBackgroundImage
                backgroundImageGroup={backgroundImageGroup}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default PickYourPerkForm;
