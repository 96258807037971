import { useTranslation } from "next-i18next";
import { Controller, SubmitHandler, useFormContext } from "react-hook-form";
import SubStepButtons from "@common/components/form/SubStepButtons";
import GoogleMapsWrapper from "@modules/location/helpers/GoogleMapsWrapper";
import { Address } from "@common/types/Address";
import { Province, provinces } from "@common/types/Province";
import Form from "@common/components/form";
import Listbox from "@common/components/form/Listbox";
import { PostalCodeInputWithValidation } from "@common/components/form/PostalCodeInput";
import customerValidation from "@common/helpers/validations/customerValidation";
import {
  FinancingPreQualificationMode,
  financialInfoMode,
  personalInfoMode,
} from "./types/FinancingPreQualificationMode";
import { PreQualificationFormProps } from "./types/FinancingPreQualificationFormProps";

type Props = {
  onChangeMode(newMode: FinancingPreQualificationMode): void;
};

const getSelectedProvince = (province: string) => {
  return provinces.find((p) => p.abbr === province);
};

const FinancingPreQualificationResidenceInfo = ({ onChangeMode }: Props) => {
  const { t } = useTranslation(["common"]);

  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
    watch,
  } = useFormContext<PreQualificationFormProps>();

  const onFormSubmit: SubmitHandler<PreQualificationFormProps> = () => {
    onChangeMode(financialInfoMode);
  };

  const onBack: () => void = () => {
    onChangeMode(personalInfoMode);
  };

  const onAutoCompleteChange = (addressInput: Address | string) => {
    if (typeof addressInput === "string") {
      setValue("address", addressInput);
    } else {
      setValue("address", addressInput.address1);
      setValue("suiteNumber", addressInput.suiteNumber || null);
      setValue("streetNumber", addressInput.streetNumber || null);
      setValue("streetName", addressInput.streetName || null);
      setValue("city", addressInput.city);
      setValue("province", addressInput.provinceAbbr);
      setValue("postalCode", addressInput.postalCode);
    }
  };

  const onClearAddressForm = () => {
    reset({
      suiteNumber: "",
      streetNumber: "",
      streetName: "",
      city: "",
      province: "",
      postalCode: "",
    });
  };

  const selectedProvince = getSelectedProvince(watch("province"));

  return (
    <div className="mx-auto mb-5 lg:w-5/6 md:w-3/4 sm:w-4/5">
      <div>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div className="flex flex-col gap-3">
            <GoogleMapsWrapper>
              <Form.AddressAutoComplete
                fieldError={errors.address}
                label={t("common:street_address")}
                placeholder={t("common:search_address")}
                onAddressSelect={onAutoCompleteChange}
                onAddressClear={onClearAddressForm}
                lookupType="address"
                showShortAddress
                {...register("address", {
                  ...customerValidation.streetAddress,
                  required: true,
                  onBlur: () => trigger("address"),
                })}
              />
            </GoogleMapsWrapper>
            <Form.Input
              type="text"
              className="w-full"
              label={t("common:city")}
              autoComplete="city"
              suppressHotjar
              feedback={errors.city?.message}
              status={errors.city ? "error" : "default"}
              maxLength={50}
              {...register("city", {
                required: {
                  value: true,
                  message: t("common:required"),
                },
                maxLength: 50,
                onBlur: () => trigger("city"),
              })}
            />
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("common:required"),
                },
              }}
              name="province"
              render={({ field: { onChange, value, ref } }) => {
                const provValue = provinces.find((p) => p.abbr === value);

                return (
                  <Listbox
                    items={provinces}
                    label={t("common:province")}
                    keyOption={(item) => item.abbr}
                    displayOption={(item) =>
                      item?.name != null ? t(item.name) : ""
                    }
                    displayButton={(item) =>
                      item?.name != null
                        ? t(item.name)
                        : t("common:select_province")
                    }
                    valueOption={(item) => item}
                    status={errors.province ? "error" : "default"}
                    feedback={errors.province?.message}
                    onChange={(provinceInput: Province) => {
                      onChange(provinceInput.abbr);
                    }}
                    selectedItem={provValue}
                    defaultButtonClassName="text-slate-400"
                    menuPosition="Up"
                    buttonRef={ref}
                  />
                );
              }}
            />
            <PostalCodeInputWithValidation
              id="postalCode"
              className="w-full"
              feedback={errors?.postalCode?.message}
              status={errors?.postalCode ? "error" : "default"}
              register={register}
              fieldKey="postalCode"
              provinceToValidate={selectedProvince}
              required
            />
          </div>
          <div className="mt-6">
            <SubStepButtons
              backButton={{ onClick: onBack }}
              alignment="justify-between"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default FinancingPreQualificationResidenceInfo;
