import Button from "@common/components/Button";
import vehicleValidation from "@common/helpers/validations/vehicleValidation";
import { useNumberMask } from "@common/hooks/useMask";
import { VinInput } from "@common/components/form/vehicle";
import { Trans, useTranslation } from "next-i18next";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import SlideToggle from "@common/components/SlideToggle";
import { CondensedCbbVehicle } from "@modules/vehicle/types/VehicleLookupResponse";
import useDebounce from "@common/hooks/useDebounce";
import {
  getCondensedCbbVehicleName,
  useCbbVehicleLookupByNameByUniqueTrim,
} from "@modules/vehicle/hooks/useCbbVehicleLookup";
import { WarningIcon } from "@common/components/icons";
import { useRouter } from "next/router";
import useVehicleDisplayFuelTypes from "@modules/vehicle/hooks/useVehicleDisplayFuelTypes";
import translate from "@common/helpers/translate";
import { Controller, SubmitHandler, useFormContext } from "react-hook-form";
import Listbox from "@common/components/form/Listbox";
import Combobox from "@common/components/form/Combobox";
import Form from "@common/components/form";
import Link from "next/link";
import useAftermarketPurchaseFromQuery from "@modules/purchase/hooks/useAftermarketPurchaseFromQuery";
import useAftermarketPurchase from "@modules/purchase/hooks/useAftermarketPurchase";
import { tryGetActiveAftermarketPurchase } from "@modules/purchase/types/Purchase";
import { WarrantyInstantQuoteFormProps } from "./types/WarrantyInstantQuoteFormProps";
import {
  personalInfoMode,
  WarrantyInstantQuoteModalMode,
} from "./types/WarrantyInstantQuoteModalMode";

export type CoverageSearchBy = "vin" | "ymm";

type Props = {
  onChangeMode(newMode: WarrantyInstantQuoteModalMode): void;
  source: string | null;
};

const queryDelayMs = 350;

const WarrantyInstantQuoteVehicleInfo = ({ onChangeMode, source }: Props) => {
  const aftermarketPurchaseFromQuery = useAftermarketPurchaseFromQuery();
  const { year, make, model, trim, vin } = aftermarketPurchaseFromQuery;

  const { t } = useTranslation(["common", "purchase"]);
  const locale = useRouter().locale || "en";
  const {
    register,
    control,
    handleSubmit,
    resetField,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useFormContext<WarrantyInstantQuoteFormProps>();
  const { maskPipe: maskNumberPipe } = useNumberMask();

  const [activeForm, setActiveForm] = useState<CoverageSearchBy>(
    vin ? "vin" : "ymm"
  );

  const { fuelTypes } = useVehicleDisplayFuelTypes();
  const fuelTypeOptions: { id: number; name: string }[] | null = useMemo(
    () =>
      fuelTypes.length > 0
        ? fuelTypes.map(({ id, name }) => ({
            id,
            name: translate(name, locale),
          }))
        : null,
    [fuelTypes, locale]
  );

  const { purchase: purchaseResponse, isLoading: isPurchaseLoading } =
    useAftermarketPurchase();
  const purchase = tryGetActiveAftermarketPurchase(purchaseResponse);

  const handleFindCoverages: SubmitHandler<
    WarrantyInstantQuoteFormProps
  > = () => {
    onChangeMode(personalInfoMode);
  };

  const handleMileageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const maskedVal = maskNumberPipe(e.target.value);
    e.target.value = maskedVal;
  };

  const [selectedVehicle, setSelectedVehicle] =
    useState<CondensedCbbVehicle | null>(null);
  const [vehicleNameQuery, setVehicleNameQuery] = useState<string>("");
  const debouncedQuery = useDebounce(vehicleNameQuery, queryDelayMs);
  const { vehicles: vehiclesFound } = useCbbVehicleLookupByNameByUniqueTrim(
    debouncedQuery,
    `aftermarket-${source ?? "unknown_source"}${
      purchase?.id ? `:${purchase.id}` : ""
    }`.trim(),
    !isPurchaseLoading
  );

  const onLookUpTypeToggleChange = (selected: CoverageSearchBy) => {
    resetField("vin");
    resetField("vehicleDetails");
    setVehicleNameQuery("");
    setSelectedVehicle(null);

    setActiveForm(selected);
  };

  const [isDefaultSet, setIsDefaultSet] = useState<boolean>(false);

  useEffect(() => {
    if (
      year &&
      make &&
      model &&
      model !== "Other" &&
      model !== "Autre" &&
      !isDefaultSet
    ) {
      const defaultVehicle = {
        year,
        make,
        model,
        trim: trim ?? undefined,
        name: getCondensedCbbVehicleName(year, make, model, trim),
      };

      setValue("vehicleDetails", defaultVehicle);
      setSelectedVehicle(defaultVehicle);
      setIsDefaultSet(true);
    }
  }, [isDefaultSet, make, model, setValue, trim, year]);

  return (
    <div>
      <SlideToggle
        leftLabel={t("common:year_make_model")}
        leftValue="ymm"
        rightLabel={t("common:vin")}
        rightValue="vin"
        value={activeForm}
        onChange={(selected) => {
          onLookUpTypeToggleChange(selected as CoverageSearchBy);
        }}
      />
      <form
        className="px-4 md:px-6 mx-auto"
        onSubmit={handleSubmit(handleFindCoverages)}
      >
        <div className="flex flex-col gap-5 text-left">
          <div className="text-center">
            <h3 className="text-primary-deep">
              {activeForm === "vin"
                ? t("purchase:enter_vin_and_details")
                : t("purchase:enter_vehicle_details")}
            </h3>
          </div>
          {activeForm === "vin" ? (
            <VinInput
              name="vin"
              register={register}
              errors={errors}
              required
              showWhereIsMyVin
            />
          ) : (
            <div>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                name="vehicleDetails"
                render={({ field: { onChange } }) => (
                  <Combobox
                    label={t("common:year_make_model_trim")}
                    items={vehiclesFound}
                    keyOption={(item) => item?.name || ""}
                    displayOption={(item) => item?.name || ""}
                    displayButton={(item) => {
                      return item?.name || "";
                    }}
                    valueOption={(item) => item}
                    selectedValue={selectedVehicle}
                    status={errors.vehicleDetails ? "error" : "default"}
                    feedback={
                      errors.vehicleDetails?.type === "required"
                        ? t("common:vehicle_description_required")
                        : ""
                    }
                    onChange={(selected) => {
                      setSelectedVehicle(selected);
                      onChange(selected);
                    }}
                    onInputChange={(value) => {
                      setVehicleNameQuery(value);
                    }}
                    hideDropdownBtn
                    itemsFilter="none"
                    itemsTextDisplay="full"
                  />
                )}
              />
              <div className="flex justify-start gap-1">
                <WarningIcon className="w-6 h-6 text-warning" />
                <div className="caption-4">
                  <Trans
                    i18nKey="search_by_ymm_disclaimer"
                    components={{
                      1: <span className="font-semibold" />,
                      2: (
                        <div
                          className="inline font-semibold text-primary-bold underline hover:cursor-pointer"
                          onClick={() => onLookUpTypeToggleChange("vin")}
                          onKeyDown={() => onLookUpTypeToggleChange("vin")}
                          role="button"
                          tabIndex={0}
                          aria-label={t("purchase:try_again_with_a_vin")}
                        />
                      ),
                    }}
                  />
                </div>
              </div>
              {fuelTypeOptions && (
                <div className="mt-5">
                  <Controller
                    name="fuelType"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, ref } }) => (
                      <Listbox
                        label={t("common:fuel_type")}
                        onChange={onChange}
                        selectedItem={watch("fuelType")}
                        items={fuelTypeOptions}
                        displayOption={(item) => item?.name ?? ""}
                        displayButton={(item) =>
                          item?.name || t("common:select_fuel_type")
                        }
                        keyOption={(p) => p.id}
                        valueOption={(p) => p}
                        buttonRef={ref}
                        feedback={
                          errors.fuelType?.type === "required" && (
                            <p className="text-error-500">
                              {t("common:fuel_type_required")}
                            </p>
                          )
                        }
                      />
                    )}
                  />
                </div>
              )}
            </div>
          )}

          <Form.Input
            id="odometer"
            type="text"
            maxLength={vehicleValidation.odometer.maxLength}
            label={t("common:odometer")}
            autoComplete="on"
            feedback={errors.odometer?.message}
            status={errors.odometer ? "error" : "default"}
            rightIcon={<span className="font-semibold">KM</span>}
            {...register("odometer", {
              required: {
                value: true,
                message: t("common:required"),
              },
              validate: {
                notZero: (value: number) =>
                  vehicleValidation.odometer.notZero(value, t),
              },
              onChange: handleMileageChange,
            })}
          />
          <Form.Checkbox
            label={
              <span className="caption-1 font-medium">
                <Trans
                  t={t}
                  i18nKey="purchase:customer_details_terms_and_conditions"
                  components={{
                    a: (
                      <Link
                        className="text-primary-bold underline"
                        href="/privacy-policy"
                        target="_blank"
                      />
                    ),
                    b: (
                      <Link
                        className="text-primary-bold underline"
                        href="/terms-conditions"
                        target="_blank"
                      />
                    ),
                  }}
                />
              </span>
            }
            feedback={
              errors.consent?.type === "required" && (
                <p className="text-error-500">
                  {t("common:form_error_required_consent")}
                </p>
              )
            }
            {...register("consent", { required: true })}
          />
        </div>

        <div className="flex flex-col justify-center gap-2 pt-8">
          <Button type="submit" disabled={isSubmitting}>
            {t("common:next")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default WarrantyInstantQuoteVehicleInfo;
