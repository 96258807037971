import SourceEnum from "@common/enums/SourceEnum";
import { TradeInContextEnum } from "@modules/trade/enums/TradeInContextEnum";

enum DealerGroupEnum {
  RightRide = 1,
  RightRideDigital = 2,
  AutoCanada = 3,
  Throttle = 4,
}

export const getDealerGroupBySource = (source: SourceEnum): DealerGroupEnum => {
  switch (source) {
    case SourceEnum.RightRide:
      return DealerGroupEnum.RightRide;
    case SourceEnum.RightRideDigital:
      return DealerGroupEnum.RightRideDigital;
    case SourceEnum.ACDealer:
      return DealerGroupEnum.AutoCanada;
    default:
      return DealerGroupEnum.RightRide;
  }
};

export const getDealerGroupByTradeInContext = (
  tradeInContext: TradeInContextEnum
): DealerGroupEnum[] => {
  switch (tradeInContext) {
    case TradeInContextEnum.RightRide:
      return [DealerGroupEnum.RightRide];
    case TradeInContextEnum.Kijiji:
      return [DealerGroupEnum.Throttle];
    case TradeInContextEnum.AutoCanada:
      return [DealerGroupEnum.AutoCanada, DealerGroupEnum.Throttle];
    default:
      return [DealerGroupEnum.RightRide, DealerGroupEnum.AutoCanada];
  }
};

export default DealerGroupEnum;
