import { forwardRef, RefObject, useImperativeHandle, useRef } from "react";
import { useTranslation } from "next-i18next";
import getBaseInputProps from "@common/helpers/form/getBaseInputProps";
import getInputStyles from "@common/helpers/form/getInputStyles";
import customerValidation from "@common/helpers/validations/customerValidation";
import { BaseInputProps } from "@common/types/FormInputProps";
import { Address } from "@common/types/Address";
import MapRef from "@modules/location/types/MapRef";
import {
  DEFAULT_CENTER,
  SEARCH_ZOOM,
} from "@modules/location/components/MapHandler";
import useSaveLocationMutation from "@modules/user/hooks/useSaveLocationMutation";
import BaseInput from "./BaseInput";
import CloseIcon from "../icons/CloseIcon";
import { SearchIcon } from "../icons";

type Props = {
  onAddressSelect: (address: Address) => void;
  onAddressClear?: () => void;
  lookupType: "address" | "(regions)";
  showShortAddress?: boolean;
  mapRef?: RefObject<MapRef>;
  updateUserLocationOnSelect?: boolean;
} & BaseInputProps;

// eslint-disable-next-line no-underscore-dangle
const _AddressAutoComplete = forwardRef<HTMLInputElement, Props>(
  (
    {
      type = "text",
      id = "street-address",
      disabled,
      autoComplete = "street-address",
      defaultValue,
      className,
      suppressHotjar = true,
      fieldError,
      onChange,
      onAddressSelect,
      onAddressClear,
      lookupType,
      showShortAddress = false,
      mapRef,
      updateUserLocationOnSelect,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation("common");
    const inputRef = useRef<HTMLInputElement>(null);
    const saveLocationMutation = useSaveLocationMutation();
    useImperativeHandle(
      ref,
      () => {
        if (inputRef.current === null) {
          return {} as HTMLInputElement; // Dummy object to satisfy TypeScript
        }
        return inputRef.current;
      },
      []
    );
    const handleOnChange = () => {
      if (inputRef.current) {
        const options = {
          fields: ["address_components", "geometry", "formatted_address"],
          componentRestrictions: { country: "ca" },
          strictBounds: false,
          types: [lookupType],
        };
        const autoCompleteInst = new google.maps.places.Autocomplete(
          inputRef.current,
          options
        );
        autoCompleteInst.addListener("place_changed", () => {
          const place = autoCompleteInst.getPlace();

          if (place.address_components) {
            const lat = place?.geometry?.location?.lat();
            const long = place?.geometry?.location?.lng();
            const addressComponents =
              place.address_components as google.maps.GeocoderAddressComponent[];

            const selectedAddress = {
              number: "",
              suite: "",
              route: "",
              city: "",
              provinceAbbr: "",
              postalCode: "",
              latitude: lat || undefined,
              longitude: long || undefined,
            };

            addressComponents.forEach((addressComponent) => {
              const addressType = addressComponent.types[0];

              switch (addressType) {
                case "street_number": {
                  selectedAddress.number = addressComponent.short_name;
                  break;
                }
                case "subpremise": {
                  selectedAddress.suite = addressComponent.long_name;
                  break;
                }
                case "route": {
                  selectedAddress.route = addressComponent.long_name;
                  break;
                }
                case "locality": {
                  selectedAddress.city = addressComponent.long_name;
                  break;
                }
                case "administrative_area_level_1": {
                  selectedAddress.provinceAbbr = addressComponent.short_name;
                  break;
                }
                case "postal_code": {
                  selectedAddress.postalCode = addressComponent.short_name;
                  break;
                }
                default:
                  break;
              }
            });

            onAddressSelect({
              address1: `${selectedAddress.number} ${selectedAddress.route}`,
              address2: selectedAddress.suite,
              city: selectedAddress.city,
              provinceAbbr: selectedAddress.provinceAbbr,
              postalCode: selectedAddress.postalCode,
              latitude: selectedAddress.latitude,
              longitude: selectedAddress.longitude,
              streetNumber: selectedAddress.number,
              streetName: selectedAddress.route,
            });

            if (showShortAddress && inputRef.current) {
              inputRef.current.value = `${selectedAddress.number} ${selectedAddress.route}`;
            }

            if (mapRef?.current && place?.geometry?.location) {
              mapRef.current.centerMapLatLng(place.geometry.location);
            }

            if (
              updateUserLocationOnSelect &&
              selectedAddress.latitude &&
              selectedAddress.longitude
            ) {
              saveLocationMutation.mutate({
                latitude: selectedAddress.latitude,
                longitude: selectedAddress.longitude,
                province: selectedAddress.provinceAbbr,
                city: selectedAddress.city,
              });
            }
          }
        });
      }
    };
    const handleReset = () => {
      if (inputRef.current) {
        inputRef.current.value = "";
      }
      if (mapRef?.current) {
        mapRef.current.centerMapLatLng(
          new google.maps.LatLng(DEFAULT_CENTER.lat, DEFAULT_CENTER.lng),
          SEARCH_ZOOM.min
        );
      }
      if (onAddressClear) onAddressClear();
    };
    const getFeedback = () => {
      if (!fieldError?.message) {
        if (fieldError?.type === "required") {
          return t("please_enter_and_select_your_postal_code_or_city");
        }
        if (fieldError?.type === "valid") {
          return t("please_select_a_valid_location");
        }
        if (fieldError?.type === "locationDisabled") {
          return t("location_disabled_error");
        }
      }
      return fieldError?.message;
    };
    const baseInputProps = getBaseInputProps({
      type,
      disabled,
      label: props.label || t("enter_postal_code_or_city"),
      status: fieldError ? "error" : "default",
      feedback: getFeedback(),
      leftIcon: <SearchIcon />,
      rightIconClickable: true,
      rightIcon: <CloseIcon className="cursor-pointer" onClick={handleReset} />,
      ...props,
    });
    const inputClassName = getInputStyles({
      leftIcon: baseInputProps.leftIcon,
      rightIcon: baseInputProps.rightIcon,
      border: baseInputProps.border,
      placeholderSize: baseInputProps.placeholderSize,
      status: baseInputProps.status,
    });
    return (
      <BaseInput id={id} className={className} {...baseInputProps}>
        <input
          id={id}
          className={inputClassName}
          type={type}
          autoComplete={autoComplete}
          maxLength={customerValidation.streetAddress.maxLength}
          defaultValue={baseInputProps.defaultValue}
          label={baseInputProps.label}
          disabled={disabled}
          ref={inputRef}
          data-hj-allow={(!suppressHotjar && type === "text") || undefined}
          onChange={(e) => {
            handleOnChange();
            if (onChange) onChange(e);
          }}
          {...props}
        />
      </BaseInput>
    );
  }
);

export default _AddressAutoComplete;
