import { gtmPushData } from "@common/helpers/gtm";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { Dispatch, ReactNode, RefObject, SetStateAction, useRef } from "react";
import LocalImage from "@common/components/LocalImage";
import RightRideLogo from "@public/images/logo-rightride.svg";
import {
  MenuIcon,
  CloseIcon,
  SortUpIcon,
  SortDownIcon,
} from "@common/components/icons";
import Button from "@common/components/Button";
import useUser from "@modules/user/hooks/useUser";
import CustomerAvatar from "@common/components/CustomerAvatar";
import { VehicleFiltersData } from "@modules/vehicle/types/vehicleFilter";
import getRelativeLink from "@modules/cms/helpers/getRelativeLink";
import classNames from "classnames";
import LocationButtonDetailed from "@modules/location/components/LocationButtonDetailed";
import ShopByModal from "./shop-by/ShopByModal";
import GetNavItems from "./getNavItems";
import usePromotionsBanner from "./hooks/usePromotionsBanner";

type Props = {
  favouritesLink: JSX.Element;
  showShopByModal: boolean;
  setShowShopByModal: Dispatch<SetStateAction<boolean>>;
  sideNavBtnRef: RefObject<HTMLButtonElement> | undefined;
  showSideNav: boolean;
  vehicleFilters: VehicleFiltersData | null;
  isFiltersLoading: boolean;
  onMobileMenuClick: () => void;
  onClose: () => void;
};

const TopNavBar = ({
  favouritesLink,
  showShopByModal,
  setShowShopByModal,
  sideNavBtnRef,
  showSideNav,
  vehicleFilters,
  isFiltersLoading,
  onMobileMenuClick,
  onClose,
}: Props) => {
  const { user } = useUser();
  const router = useRouter();
  const { t } = useTranslation(["common"]);
  const shopByBtnRef = useRef(null);

  const {
    data: promo,
    error,
    isFetchedAfterMount,
  } = usePromotionsBanner(
    router.isPreview,
    router.locale?.toUpperCase() as string
  );

  const handlePromoClick = (title: string) => {
    gtmPushData({ featureTileTitle: title });
  };

  const renderBanner = () => {
    let response: ReactNode = "";
    if (!error && promo?.promoLink?.title && promo?.promoLink?.url) {
      response = (
        <div
          id="promoBanner"
          className={classNames("text-sm text-primary-bold", {
            "animated fadeInDown": isFetchedAfterMount,
          })}
        >
          <Link
            href={`${getRelativeLink(promo.promoLink.url)}`}
            onKeyUp={() => {
              handlePromoClick(promo.promoLink.title);
            }}
            onClick={() => {
              handlePromoClick(promo.promoLink.title);
            }}
            role="link"
            tabIndex={0}
            className="caption-1 focus:outline-none focus-visible:shadow-elevation-03"
          >
            {`${promo.promoLink.title}`}
          </Link>
        </div>
      );
    }

    return response;
  };

  const locationButton = () => {
    return <LocationButtonDetailed navLink />;
  };

  return (
    <div>
      <ShopByModal
        showModal={showShopByModal}
        makes={vehicleFilters?.makesAndModels.flatMap((m) => m.name)}
        bodyStyles={vehicleFilters?.bodyStyles}
        isLoading={isFiltersLoading}
        shopBtnRef={shopByBtnRef}
        onClose={onClose}
      />
      <header className="hidden 2md:block">
        <nav className="bg-primary-light" aria-label="Top Banner">
          <div className="mx-auto py-.5 px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between">
              {promo && renderBanner()}
              <div className="flex items-center ml-auto">
                {GetNavItems("TopSubNav").map((link) => {
                  return (
                    <Link
                      key={link.url}
                      href={link.url}
                      role="link"
                      tabIndex={0}
                      className="px-4 py-2 caption-1 text-text-light-100 flex items-center focus:outline-none focus-visible:shadow-elevation-03"
                    >
                      {link.title}
                    </Link>
                  );
                })}
                <span className="pl-4 py-1">{locationButton()}</span>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <nav className="bg-background-light-100" aria-label="Navigation Bar">
        <div className="py-4 pb-3 px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between">
            <div className="flex-shrink-0 [&>span]:align-middle">
              <Link
                href="/"
                passHref
                role="link"
                tabIndex={0}
                className="[&>*]:align-middle focus:outline-none focus-visible:shadow-elevation-03"
              >
                <LocalImage
                  width="173"
                  height="39"
                  className="hover:cursor-pointer"
                  src={RightRideLogo}
                  alt="RightRide Logo"
                  priority
                />
              </Link>
            </div>
            <div className="flex items-center">
              <div className="hidden 2md:block">
                <div className="ml-2 flex items-center">
                  <Button
                    fill="link"
                    spacing="tight-hug-icon"
                    className="px-5 !text-black focus:outline-none focus-visible:shadow-elevation-03"
                    rightIcon={
                      showShopByModal ? (
                        <SortUpIcon
                          className="text-primary-bold"
                          fontSize="1.5rem"
                        />
                      ) : (
                        <SortDownIcon
                          className="text-primary-bold"
                          fontSize="1.5rem"
                        />
                      )
                    }
                    onClick={() => setShowShopByModal(!showShopByModal)}
                    ref={shopByBtnRef}
                  >
                    {t("common:shop")}
                  </Button>

                  {GetNavItems("Top").map((link) => {
                    return (
                      <Link
                        key={link.url}
                        href={link.url}
                        role="link"
                        tabIndex={0}
                        className="py-2 px-4 body-3 rounded-full text-text-light-100 active:bg-primary-light hover:bg-background-light-300 focus:outline-none focus-visible:shadow-elevation-03"
                      >
                        {link.title}
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="2md:hidden flex items-center -mr-2">
              <span className="px-[.2rem]">{favouritesLink}</span>
              {user && user.isLoggedIn && (
                <Button
                  fill="link"
                  spacing="tight-hug"
                  size="small"
                  className="px-[.1rem]"
                  onClick={() => {
                    router.push("/profile");
                  }}
                >
                  <CustomerAvatar
                    customer={user}
                    size="sm"
                    isCurrentUser
                    isProfileAvatar
                  />
                </Button>
              )}
              <Button
                fill="link"
                spacing="tight-hug"
                size="small"
                className="px-[.1rem]"
                onClick={onMobileMenuClick}
                ref={sideNavBtnRef}
              >
                {!showSideNav ? (
                  <MenuIcon fontSize="2em" fill="#293EDD" />
                ) : (
                  <CloseIcon fontSize="2em" fill="#293EDD" />
                )}
              </Button>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default TopNavBar;
