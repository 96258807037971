import DealerGroupEnum from "@common/enums/DealerGroupEnum";
import api from "@modules/api/api";
import Location from "@modules/locations/types/Location";
import { QueryClient, useQuery } from "@tanstack/react-query";
import locationQueryKeys from "../locationQueryKeys";

const getLocations = (dealerGroupId: number): Promise<Location[]> => {
  const searchParams = new URLSearchParams();
  searchParams.append("dealerGroupId", dealerGroupId.toString());
  return api.get<Location[]>("api/locations", { searchParams });
};

export const prefetchLocations = async (queryClient: QueryClient) => {
  await queryClient.prefetchQuery({
    queryKey: ["locationList"],
    queryFn: () => getLocations(DealerGroupEnum.RightRide),
  });
};

const defaultSort = (locations: Location[]): Location[] => {
  return locations.sort((a, b) => a.name.localeCompare(b.name));
};

export default function useGetLocations(
  dealerGroupId = DealerGroupEnum.RightRide,
  enabled = true
) {
  const { data, error } = useQuery({
    queryKey: locationQueryKeys.list(dealerGroupId),
    queryFn: () => getLocations(dealerGroupId),
    enabled,
  });

  return {
    locations: data ? defaultSort(data) : [],
    isLoading: !error && !data,
    error,
  };
}
