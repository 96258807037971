import classNames from "classnames";
import { ChangeEvent, ReactNode } from "react";

type Props<T extends Record<string, unknown>> = {
  id: string;
  children: ReactNode;
  className?: string;
  objectValue: T;
  onChangeEvent: (event: ChangeEvent<HTMLInputElement>, objectValue: T) => void;
  isChecked: boolean;
  readOnly?: boolean;
  paddingSize?: "small" | "medium";
};

const paddingMap = {
  small: "py-3",
  medium: "py-8",
};

const DivCheckbox = <T extends Record<string, unknown>>({
  id,
  children,
  className,
  objectValue,
  onChangeEvent,
  isChecked,
  readOnly = false,
  paddingSize = "small",
}: Props<T>) => {
  return (
    <div className="relative flex flex-col h-full">
      <input
        id={id}
        type="checkbox"
        className="opacity-0 absolute left-0 top-0 peer"
        onChange={(event) => onChangeEvent(event, objectValue)}
        checked={isChecked}
        readOnly={readOnly}
      />
      <label
        htmlFor={id}
        className={classNames(
          "flex flex-col justify-center items-center text-center content-center w-full h-full peer-checked:border-primary-bright peer-checked:ring-1 peer-checked:ring-offset-0 peer-checked:ring-primary-bright peer-checked:shadow-none peer-checked:text-primary-bold peer-checked:[&>svg]:text-primary-bold lg:hover:border-primary-bright peer-focus:border-primary-bright rounded-[4px] border-gray-50 border select-none cursor-pointer",
          paddingMap[paddingSize],
          className
        )}
      >
        {children}
      </label>
    </div>
  );
};

export default DivCheckbox;
