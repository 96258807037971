import { Translation } from "@common/types/Translation";

export type PaymentFrequency = {
  id: number;
  name: Translation;
};

const WeeklyPaymentFrequency: PaymentFrequency = {
  id: 1,
  name: { english: "Weekly", french: "Hebdomadaire" },
};

const BiWeeklyPaymentFrequency: PaymentFrequency = {
  id: 2,
  name: { english: "Bi-Weekly", french: "/2 sem" },
};

const SemiMonthlyPaymentFrequency: PaymentFrequency = {
  id: 3,
  name: { english: "Semimonthly", french: "Bimensuel" },
};

const MonthlyPaymentFrequency: PaymentFrequency = {
  id: 4,
  name: { english: "Monthly", french: "Mensuel" },
};

const paymentFrequencies = [
  WeeklyPaymentFrequency,
  BiWeeklyPaymentFrequency,
  SemiMonthlyPaymentFrequency,
  MonthlyPaymentFrequency,
];

export const PaymentFrequencies = {
  weekly: WeeklyPaymentFrequency,
  biWeekly: BiWeeklyPaymentFrequency,
  semiMonthly: SemiMonthlyPaymentFrequency,
  monthly: MonthlyPaymentFrequency,
};

export function tryGetPaymentFrequencyById(
  id?: number | null
): PaymentFrequency | null {
  if (!id) return null;

  return (
    paymentFrequencies.find((paymentFrequency) => paymentFrequency.id === id) ||
    null
  );
}

export function tryGetPaymentFrequencyByName(
  name: string
): PaymentFrequency | null {
  return (
    paymentFrequencies.find(
      (paymentFrequency) =>
        paymentFrequency.name.english.toLowerCase() === name.toLowerCase() ||
        paymentFrequency.name.french.toLowerCase() === name.toLowerCase()
    ) || null
  );
}
