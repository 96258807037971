import { useEffect } from "react";
import Modal from "@common/components/Modal";
import TextLink from "@common/components/TextLink";
import { gtmPushData } from "@common/helpers/gtm";
import { PROTECTION_RIGHTRIDE_CA_EMAIL } from "@common/constants";
import { useTranslation } from "next-i18next";
import { Event as HotjarEvent, hotjar } from "@modules/core/hotjar/types";

type Props = {
  showNoCoveragesModal: boolean;
  setShowNoCoveragesModal: (show: boolean) => void;
  onPrimaryClick: () => void;
  primaryButtonText: string;
  modalMode?: "Landing" | "Generic" | "OnlyInquiry";
  userId?: string;
};

const NoCoveragesModal = ({
  showNoCoveragesModal,
  setShowNoCoveragesModal,
  onPrimaryClick,
  primaryButtonText,
  modalMode = "Landing",
  userId,
}: Props) => {
  const { t } = useTranslation(["common", "purchase"]);

  useEffect(() => {
    gtmPushData({
      event: "AftermarketPurchase",
      element: "Landing Page",
      description: "No Coverages Found Modal",
      user_id: userId,
    });
    hotjar.event(HotjarEvent.AftermarketPurchaseNoCoverages);
  }, [userId]);

  return (
    <Modal
      isOpen={showNoCoveragesModal}
      onClose={() => setShowNoCoveragesModal(false)}
      title={t("purchase:no_coverages_found")}
      alignment="center"
      buttonDisplay="block"
    >
      <h1 className="text-primary-bold">{t("common:hmm")}</h1>
      <h3 className="mt-2 text-primary-deep">
        {t("purchase:unfortunately_it_doesnt_look_like")}
      </h3>
      <div className="b-2 mt-4">
        {t("purchase:sometimes_there_just_isnt_any_warranty")}
      </div>
      {modalMode === "Landing" && (
        <>
          <Modal.Buttons
            primaryButtonText={t(
              "purchase:find_coverages_for_a_different_vehicle"
            )}
            onPrimaryClick={onPrimaryClick}
          />
          <TextLink
            className="p-4 focus:ring-0"
            href={`mailto:${PROTECTION_RIGHTRIDE_CA_EMAIL}`}
            standAlone
          >
            <a>{t("purchase:inquire_about_other_options")}</a>
          </TextLink>
        </>
      )}
      {modalMode === "Generic" && (
        <Modal.Buttons
          primaryButtonText={primaryButtonText}
          onPrimaryClick={onPrimaryClick}
        />
      )}
      {modalMode === "OnlyInquiry" && (
        <TextLink
          className="p-4 focus:ring-0"
          href={`mailto:${PROTECTION_RIGHTRIDE_CA_EMAIL}`}
          standAlone
        >
          <a>{t("purchase:inquire_about_other_options")}</a>
        </TextLink>
      )}
    </Modal>
  );
};

export default NoCoveragesModal;
