import { NameId } from "@common/types/NameId";

export enum TradeInContextEnum {
  RightRide = 1,
  Kijiji = 2,
  AutoCanada = 3,
}

export const tradeInContexts: NameId[] = [
  { id: "1", name: "RightRide" },
  { id: "2", name: "Kijiji" },
  { id: "3", name: "AutoCanada" },
];

export function getTradeInContextName(context: TradeInContextEnum): string {
  return (
    tradeInContexts.find((item) => item.id === context.toString())?.name ?? ""
  );
}

export function isTradeInContextEqual(
  id: string,
  context: TradeInContextEnum
): boolean {
  return id === context.toString();
}
